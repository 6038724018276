import {
  CheckCircleOutlined,
  CloseOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Tag, message } from "antd";
import dayjs from "dayjs";
import { DateFormats } from "../types/typesConstants";
const open_pdf_file_tab = (file, filename) => {
  const fileURL = URL.createObjectURL(file);
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = fileURL;
  a.target = "_blank";
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(fileURL);
};

const download_FileObject = (base64String, filename = "document.pdf") => {
  const linkSource = base64String;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.target = "_blank";
  downloadLink.click();
};

const open_pdf_file = (data, filename = "document.pdf") => {
  const file = new Blob([data], { type: "application/pdf" });
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  // const pdfWindow = window.open("about:blank");
  // pdfWindow.location.href = fileURL;

  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = fileURL;
  a.download = filename;
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(fileURL);
};
const open_file = (
  data,
  filename = "document.pdf",
  content_type = "application/pdf"
) => {
  const file = new Blob([data], { type: content_type });
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);

  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = fileURL;
  a.download = filename;
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(fileURL);
};
const base64toBlob = (data) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const get_Base64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const to_Base64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const is_valid_image_file = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("Solo puede subir archivos tipo JPG/PNG!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("La imagen debe tener menos de 2 MB!");
  }
  return isJpgOrPng && isLt2M;
};
const get_image_base64 = (images) => {
  let image = "";
  if (images.length > 0) image = images[0]?.thumbUrl;
  return image;
};
const validateEmail = (email) => {
  email = String(email).trim();
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
  // return String(email)
  //   .toLowerCase()
  //   .match(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //   );
};
const configDataGraphits = (label, aLabels, aData) => ({
  labels: aLabels,
  datasets: [
    {
      label: label,
      data: aData,
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
});
const TagRowTable = ({ estado }) => {
  if (estado) {
    return (
      <Tag color="success" icon={<CheckCircleOutlined />}>
        SI
      </Tag>
    );
  } else {
    return (
      <Tag color="default" icon={<MinusCircleOutlined />}>
        NO
      </Tag>
    );
  }
};
const labelWithErrorOrNot = (tabKeyError, key, label) => {
  if (tabKeyError === key) {
    label = (
      <span style={{ color: "#ff4d4f" }}>
        <CloseOutlined />
        {label}
      </span>
    );
  } else label = <span>{label}</span>;
  return label;
};
const fnCalculoPrecioUnitario = (valorunitario, porcentajeimpuesto) => {
  const valorigvunitario = parseFloat(
    parseFloat((porcentajeimpuesto / 100) * valorunitario).toFixed(4)
  );
  const preciounitario = parseFloat(
    parseFloat(valorunitario + valorigvunitario).toFixed(4)
  );
  return { valorigvunitario, preciounitario };
};
const fnCalculoValorUnitario = (preciounitario, porcentajeimpuesto) => {
  const valorunitario = parseFloat(
    parseFloat(
      preciounitario / (1 + parseFloat(porcentajeimpuesto) / 100)
    ).toFixed(4)
  );

  const valorigvunitario = parseFloat(
    parseFloat(valorunitario * (parseFloat(porcentajeimpuesto) / 100)).toFixed(
      4
    )
  );
  return { valorigvunitario, valorunitario };
};
const parseNumeric = (value, decimals = 2) =>
  parseFloat(parseFloat(value).toFixed(decimals));
const parseDateFormatBackEnd = (date) =>
  dayjs(date).format(DateFormats.backend);
const parseDateFormatFrontEnd = (date) =>
  dayjs(date).format(DateFormats.frontend);
export {
  open_pdf_file_tab,
  open_pdf_file,
  open_file,
  get_Base64,
  is_valid_image_file,
  to_Base64,
  get_image_base64,
  validateEmail,
  configDataGraphits,
  TagRowTable,
  labelWithErrorOrNot,
  base64toBlob,
  b64toBlob,
  download_FileObject,
  parseNumeric,
  parseDateFormatBackEnd,
  parseDateFormatFrontEnd,
  fnCalculoPrecioUnitario,
  fnCalculoValorUnitario,
};
