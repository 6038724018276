import React from "react";
import { TableUi } from "../../../../ui/TableUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  EstadoProgramacionTejido,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import {
  get_pdf_programacion_tejido,
  programacionTejidoSetActive,
  programacionTejidoStartDelete,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { showDeleteConfirm } from "../../../../../helpers/confirmModal";
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  open_pdf_file,
  parseDateFormatFrontEnd,
  parseNumeric,
} from "../../../../../helpers/utils";
import { EstadoProgramacionTag } from "../../reusable/EstadoProgramacionTag";
const opts = { editar: "edit", eliminar: "delete", reporte: "report" };
const items = (data) => {
  const disabledDelete = !(data?.estado === EstadoProgramacionTejido.PROCESO);
  return [
    {
      key: opts.editar,
      label: (
        <>
          <EditOutlined /> Editar
        </>
      ),
    },
    {
      key: opts.eliminar,
      label: (
        <>
          <DeleteOutlined /> Eliminar
        </>
      ),
      disabled: disabledDelete,
    },
    { type: "divider" },
    {
      key: opts.reporte,
      label: (
        <>
          <FilePdfOutlined style={{ color: "#FF0000" }} /> P.Tejeduria
        </>
      ),
    },
  ];
};
const handleMenuClick = (
  e,
  record,
  { handleEditar, handleEliminar, handlePDFProgramacionTejido }
) => {
  if (e.key === opts.editar) handleEditar(record);
  else if (e.key === opts.eliminar) handleEliminar(record);
  else if (e.key === opts.reporte) handlePDFProgramacionTejido(record);
};
const columns_table = (acciones) => {
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechainicio",
      key: "fechainicio",
      render: (text) => <>{parseDateFormatFrontEnd(text)}</>,
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor_nombre",
      key: "proveedor_nombre",
      align: "center",
      render: (text, record) => (
        <>
          {text}
          <h5 style={{ margin: 0 }}>{record?.proveedor_ruc}</h5>
        </>
      ),
    },
    {
      title: (
        <>
          <span>Cantidad</span> <br /> <sub>(Rollos, Cuello,etc)</sub>
        </>
      ),
      dataIndex: "cantidadtotal",
      key: "cantidadtotal",
      align: "center",
    },

    {
      title: (
        <>
          <span>Peso bruto</span> <br /> <sub>(KG)</sub>
        </>
      ),
      dataIndex: "pesobrutototal",
      key: "pesobrutototal",
      align: "center",
    },
    {
      title: (
        <>
          <span>Peso neto</span> <br /> <sub>(KG)</sub>
        </>
      ),
      dataIndex: "pesonetototal",
      key: "pesonetototal",
      align: "center",
    },
    {
      title: "Merma",
      dataIndex: "merma",
      key: "merma",
      align: "center",
      render: (text, record) => (
        <>
          {parseNumeric(record?.pesobrutototal - record?.pesonetototal)}
          <h5 style={{ margin: 0 }}>
            {parseNumeric(
              parseNumeric(record?.pesobrutototal - record?.pesonetototal) /
                parseNumeric(record?.pesobrutototal)
            ) *
              100 +
              "%"}
          </h5>
        </>
      ),
    },
    {
      title: "Estado",
      dataIndex: "nombreestado",
      key: "nombreestado",
      render: (text, record) => (
        <EstadoProgramacionTag estado={record?.estado} estado_nombre={text} />
      ),
      align: "center",
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      align: "center",
      render: (text, record) => (
        <>
          <Dropdown
            menu={{
              items: items(record),
              onClick: (e) => handleMenuClick(e, record, acciones),
            }}
          >
            <Button type="text">
              <MoreOutlined />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];
};
export const ProgramacionTejidoTable = ({ data, loading }) => {
  const dispatch = useDispatch();
  const handleEditar = (programaciontejido) => {
    dispatch(programacionTejidoSetActive(programaciontejido));
    dispatch(showFormProgramacionTejido());
  };
  const handleEliminar = (programaciontejido) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la programación de tejido?",
      programaciontejido.codigo,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(programacionTejidoStartDelete(programaciontejido));
        }
      }
    );
  };
  const handlePDFProgramacionTejido = async (programaciontejido) => {
    const file = await get_pdf_programacion_tejido(programaciontejido?.id);
    let name_file = programaciontejido?.codigo + ".pdf";
    open_pdf_file(file, name_file);
  };
  return (
    <TableUi
      columns={columns_table({
        handleEditar,
        handleEliminar,
        handlePDFProgramacionTejido,
      })}
      rowKey="id"
      dataSource={data}
      size="small"
      loading={loading}
    />
  );
};
