import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { FormUi } from "../../ui/FormUi";
import { ComprobantePagoForm } from "./ComprobantePagoForm";
import { useDispatch, useSelector } from "react-redux";
import { cajaAbiertaSetActive } from "../../../actions/caja";
import { useNavigate } from "react-router-dom";
import { messageError } from "../../../helpers/messageConfig";
import { ItemDetalleModal } from "./modal/ItemDetalleModal";
import { parseDateFormatBackEnd, parseNumeric } from "../../../helpers/utils";
import { detallecomprobantepagoClearActive } from "../../../actions/comprobante";
import dayjs from "dayjs";
import { CondicionPago } from "../../../types/typesConstants";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  tipocomprobante: data?.tipocomprobante || null,
  seriecomprobante: data?.seriecomprobante || null,
  nrocomprobante: data?.nrocomprobante || "NEW",
  cliente: data?.cliente || null,
  fechaemision: data?.fechaemision || dayjs(),
  fechavencimiento: data?.fechavencimiento || dayjs(),
  montoingresado: data?.montoingresado || 0,
  montovuelto: data?.montovuelto || 0,
  formapago: data?.formapago || CondicionPago.CONTADO,
  detallescomprobantepago: data?.detallescomprobantepago || null,
  empresa: data?.empresa || null,
  descuentoglobal: data?.descuentoglobal || 0,
  montototaldescuento: data?.montototaldescuento || 0,
  montototalgravadas: data?.montototalgravadas || 0,
  montototalexoneradas: data?.montototalexoneradas || 0,
  montototalinafectas: data?.montototalinafectas || 0,
  montoigvtotal: data?.montoigvtotal || 0,
});
export const ComprobantePagoScreen = ({ handleCloseForm }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeComprobanteVenta, activeDetalleComprobanteVenta } = useSelector(
    (state) => state.venta
  );
  const [openItemModal, setOpenItemModal] = useState(false);
  const [cajaabierta, setCajaabierta] = useState(
    JSON.parse(localStorage.getItem("caja")) || null
  );
  const navigate = useNavigate();
  const handleNuevoDetalle = () => setOpenItemModal(true);
  const handleCancelModal = () => setOpenItemModal(false);
  useEffect(() => {
    if (cajaabierta) {
      dispatch(cajaAbiertaSetActive(cajaabierta));
    } else {
      navigate("/caja");
    }
    return () => {
      setCajaabierta(null);
    };
  }, [cajaabierta]);
  const handleSubmit = (values) => {
    values = {
      ...values,
      fechaemision: parseDateFormatBackEnd(values?.fechaemision),
      fechavencimiento: parseDateFormatBackEnd(values?.fechavencimiento),
      aperturacaja: cajaabierta?.id,
      empresa: activeEmpleado?.empresa?.id,
    };
    console.log(values);
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormComprobantePago, ItemDetalleForm } = forms;
        if (name === "ItemDetalleForm") {
          const _valorventa = values?.preciounitario * values?.cantidad;
          const _valorventabruto = values?.cantidad * values?.valorunitario;
          const valuesData = {
            ...values,
            valorigv: parseNumeric(values?.cantidad * values?.valorigvunitario),
            valorventabruto: parseNumeric(_valorventabruto),
            valorventa: parseNumeric(_valorventa - values?.descuentos),
          };

          const detallescomprobantepago =
            FormComprobantePago.getFieldValue("detallescomprobantepago") || [];
          if (activeDetalleComprobanteVenta) {
            const _detallescomprobantesPago = detallescomprobantepago.map((e) =>
              e.id === valuesData.id ? valuesData : e
            );
            FormComprobantePago.setFieldsValue({
              detallescomprobantepago: _detallescomprobantesPago,
            });
          } else {
            const _existe = detallescomprobantepago?.filter(
              (x) => x.item === values?.item
            )?.length;
            if (_existe < 1) {
              FormComprobantePago.setFieldsValue({
                detallescomprobantepago: [
                  ...detallescomprobantepago,
                  valuesData,
                ],
              });
            } else
              messageError(
                "el item ya existe en el detalle del comprobante pago"
              );
          }
          dispatch(detallecomprobantepagoClearActive());
          handleCancelModal();
        }
        if (name === "FormSerie") {
          const series = values?.series?.filter(
            (item) => item.selected === true
          );
          ItemDetalleForm.setFieldValue("series", series);
        }
        if (name === "FormLote") {
          const valuesLotes = values?.lotes
            ?.filter((e) => e.cantidad_ingresada !== null)
            .map((e) => ({
              ...e,
              stockactual: e.cantidad,
              cantidad: e.cantidad_ingresada,
            }));
          ItemDetalleForm.setFieldValue("lotes", valuesLotes);
        }
      }}
    >
      <FormUi
        name="FormComprobantePago"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues()}
        handleSubmit={handleSubmit}
      >
        <ComprobantePagoForm
          handleNuevoDetalle={handleNuevoDetalle}
          handleCloseForm={handleCloseForm}
        />
      </FormUi>
      <ItemDetalleModal
        open={openItemModal}
        onCancelModal={handleCancelModal}
      />
    </Form.Provider>
  );
};
