import { Col, Divider, Flex, Form, Radio, Row, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  TipoButtonConstants,
  TipoComprobanteSunatConstants,
  TypeSizeReportConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { CardUi } from "../../ui/CardUi";
import { useDispatch, useSelector } from "react-redux";
import {
  comprobantePagoResultShowForm,
  get_pdf_boleta_venta,
  get_pdf_factura_venta,
  get_pdf_nota_venta,
} from "../../../actions/comprobante";
import { ComprobanteReportLayoutUi } from "../../compras/comprobantecompra/ComprobanteReportLayoutUi";
import { ComproPagoResultHeaderPart1 } from "./reusable/ComproPagoResultHeaderPart1";
import { ComproPagoMediosPagoPart } from "./reusable/ComproPagoMediosPagoPart";
import { ComproPagoSendEmailPart } from "./reusable/ComproPagoSendEmailPart";

const optionsSizeReport = [
  { value: TypeSizeReportConstants.R_A4, label: TypeSizeReportConstants.R_A4 },
  { value: TypeSizeReportConstants.R_80, label: TypeSizeReportConstants.R_80 },
];
const initialValues = (data = {}) => ({
  moneda_prefijosunat: data?.moneda_prefijosunat || null,
  monto_total: data?.monto_total || 0,
  mediospago: data?.mediospago || [],
});

export const ComprobantePagoResultForm = () => {
  const { activeComprobantePago } = useSelector(
    (state) => state.comprobantepago
  );
  const [archivo, setArchivo] = useState(null);
  const [sizeType, setSizeType] = useState(TypeSizeReportConstants.R_80);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleCancelForm = () => dispatch(comprobantePagoResultShowForm(false));
  const file_comprobante_pago = async (
    comprobante,
    size_type = TypeSizeReportConstants.R_A4
  ) => {
    let file = null;
    switch (comprobante.tipocomprobantesunat) {
      case TipoComprobanteSunatConstants.Boleta:
        file = await get_pdf_boleta_venta(comprobante?.id, size_type);
        break;
      case TipoComprobanteSunatConstants.Factura:
        file = await get_pdf_factura_venta(comprobante?.id, size_type);
        break;
      case TipoComprobanteSunatConstants.NotaVenta:
        file = await get_pdf_nota_venta(comprobante?.id, size_type);
        break;
      default:
        file = null;
        break;
    }
    if (file !== null) {
      const fileURL = URL.createObjectURL(file);
      setArchivo(fileURL);
    }
  };

  const handleChangeSizeReporteComprobante = (e) => {
    const sizeReport = e.target.value;
    setSizeType(sizeReport);
    file_comprobante_pago(activeComprobantePago, sizeReport);
  };
  useEffect(() => {
    file_comprobante_pago(activeComprobantePago);
  }, []);

  return (
    <CardUi
      key="cardComprobanteResult"
      title={`${activeComprobantePago.cliente_nombre} / ${activeComprobantePago.tipocomprobante_nombre} ${activeComprobantePago.comprobante}`}
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Salir}
              onClick={handleCancelForm}
            />
          </Tooltip>
          {/* <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormComprobanteCompra"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip> */}
        </Space>,
      ]}
    >
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={16}>
          {archivo && <ComprobanteReportLayoutUi fileUrl={archivo} />}
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={8}>
          {/* <Form.Provider
            onFormFinish={(name, { values, forms }) => {
              const { ComprobantePagoResultForm } = forms;
              const mediospago =
                ComprobantePagoResultForm.getFieldValue("mediospago") || [];
              ComprobantePagoResultForm.setFieldsValue({
                mediospago: [...mediospago, values],
              });
            }}
          > */}
          <Form
            name="ComprobantePagoResultForm"
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={initialValues(activeComprobantePago)}
          >
            <Row>
              <Col span={24}>
                <Flex gap="small" align="center" vertical>
                  <Radio.Group
                    block
                    options={optionsSizeReport}
                    defaultValue={TypeSizeReportConstants.R_A4}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={handleChangeSizeReporteComprobante}
                  />
                </Flex>
              </Col>
              <Divider style={{ margin: "12px 0" }} />
              <Col span={24}>
                <ComproPagoResultHeaderPart1
                  comprobantepago={activeComprobantePago}
                />
              </Col>
              <Divider style={{ margin: "12px 0" }} />
              <Col span={24}>
                <ComproPagoMediosPagoPart
                  mediospago={activeComprobantePago?.mediospago}
                />
              </Col>
              <Divider style={{ margin: "12px 0" }} />
              <Col span={24}>
                <ComproPagoSendEmailPart
                  comprobantepago={activeComprobantePago}
                />
              </Col>
            </Row>
          </Form>
          {/* </Form.Provider> */}
        </Col>
      </Row>
    </CardUi>
  );
};
