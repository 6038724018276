import { Descriptions, Form, Input, theme, InputNumber } from "antd";
import React, { useEffect } from "react";
import { parseNumeric } from "../../../../helpers/utils";

const fnCalculosTotales = (detallescomprobantepago) => {
  const productoscarrito = detallescomprobantepago || [];
  //Calculos totales
  let SumTotal = 0;
  productoscarrito?.forEach((item) => {
    SumTotal += parseFloat(item.totalventa);
  });
  SumTotal = parseFloat(SumTotal).toFixed(2);
  const SumTotalIGV = parseFloat(0.18 * SumTotal).toFixed(2);
  const SumGravada = parseFloat(SumTotal - SumTotalIGV).toFixed(2);
  return { SumGravada, SumTotalIGV, SumTotal };
};
export const PosCarritoFooter = () => {
  const formInstance = Form.useFormInstance();
  const [form] = Form.useForm(formInstance);
  const prefijo = Form.useWatch("prefijo", form);
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const { token } = theme.useToken();
  const { setFieldValue } = form;
  const inputStyle = {
    fontSize: 16,
    color: token.colorPrimary,
    fontWeight: "bold",
  };
  const labelStyle = {
    width: "75%",
    fontWeight: "bold",
    justifyContent: "right",
    alignItems: "center",
  };
  // TODO: Calculo de los totales del comprobante de venta
  useEffect(() => {
    const { SumGravada, SumTotalIGV, SumTotal } =
      fnCalculosTotales(detallecomprobante);
    setFieldValue("montosubtotal", parseNumeric(SumGravada));
    setFieldValue("montoigv", parseNumeric(SumTotalIGV));
    setFieldValue("montototal", parseNumeric(SumTotal));
  }, [detallecomprobante, setFieldValue]);

  return (
    <>
      <div className="pos__carrito__footer">
        <Descriptions
          column={1}
          size="small"
          className="pos__carrito__footer__totals"
        >
          <Descriptions.Item
            label="SUB-TOTAL"
            labelStyle={labelStyle}
            style={{ paddingBottom: 2 }}
          >
            <Form.Item name="montosubtotal" style={{ marginBottom: 0 }}>
              <InputNumber
                variant="borderless"
                readOnly={true}
                prefix={prefijo || "S/"}
                precision={2}
                style={inputStyle}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            label="IGV"
            labelStyle={labelStyle}
            style={{ paddingBottom: 2 }}
          >
            <Form.Item name="montoigv" style={{ marginBottom: 0 }}>
              <InputNumber
                variant="borderless"
                readOnly={true}
                prefix={prefijo || "S/"}
                precision={2}
                style={inputStyle}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            label="TOTAL"
            labelStyle={labelStyle}
            style={{ paddingBottom: 2 }}
          >
            <Form.Item name="montototal" style={{ marginBottom: 0 }}>
              <InputNumber
                variant="borderless"
                readOnly={true}
                prefix={prefijo || "S/"}
                precision={2}
                style={inputStyle}
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
};
