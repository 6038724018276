import React, { useEffect, useState } from "react";
import { get_users_actives } from "../../../../actions/empleado";
import { SearchBasicSelectUI } from "../../../ui/SearchBasicSelectUI";

export const UsuariosSelect = ({ ...props }) => {
  const [usuarios, setUsuarios] = useState([]);
  const OnLoadUsuarios = async () => {
    const datos = await get_users_actives();
    setUsuarios(datos);
  };
  useEffect(() => {
    OnLoadUsuarios();
  }, []);
  return <SearchBasicSelectUI data={usuarios} {...props} />;
};
