import React, { useEffect, useState } from "react";
import { SearchBasicSelectUI } from "../../ui/SearchBasicSelectUI";
import { get_almacenes_empledo } from "../../../actions/inventario";

export const AlmacenEmpleadoSelect = ({ empleado, ...props }) => {
  const [almacenes, setAlmacenes] = useState([]);
  const OnLoadAlmacenes = async (empleado) => {
    const datos = await get_almacenes_empledo(empleado);
    setAlmacenes(datos);
  };
  useEffect(() => {
    OnLoadAlmacenes(empleado);
  }, [empleado]);

  return <SearchBasicSelectUI data={almacenes} {...props} />;
};
