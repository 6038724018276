import { Descriptions, Dropdown } from "antd";
import { PaymentMethodType } from "../../../../types/typesConstants";
import {
  parseDateFormatFrontEnd,
  parseNumeric,
} from "../../../../helpers/utils";
import { showDeleteConfirm } from "../../../../helpers/confirmModal";
import { useDispatch } from "react-redux";
import {
  mediopagoByComprobantePagoStartDelete,
  mediopagocomprobantepagoSetActive,
} from "../../../../actions/comprobante";
import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
const keyMenu = { edit: "edit", delete: "delete" };
const itemsMenu = [
  {
    key: keyMenu.edit,
    label: (
      <>
        <EditOutlined /> Editar
      </>
    ),
  },
  {
    key: keyMenu.delete,
    label: (
      <>
        <DeleteOutlined /> Eliminar
      </>
    ),
  },
];

export const MediosPagoContentItem = ({ row, handleOpenModal }) => {
  const dispatch = useDispatch();
  const handleEditar = (data) => {
    dispatch(mediopagocomprobantepagoSetActive(data));
    handleOpenModal();
  };
  const handleRemove = (data) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar el medio de pago?",
      `${data?.mediopago_nombre} - ${parseDateFormatFrontEnd(data?.fechapago)}`,
      (confirmed) => {
        if (confirmed) dispatch(mediopagoByComprobantePagoStartDelete(data));
      }
    );
  };
  const handleMenuClick = (e) => {
    if (e.key === keyMenu.edit) handleEditar(row);
    if (e.key === keyMenu.delete) handleRemove(row);
  };
  return (
    <Descriptions
      column={{ xxl: 6, xl: 5, lg: 4, md: 3, sm: 1, xs: 1 }}
      className="pos__carrito__mediospago__list__item"
      size="small"
    >
      <Descriptions.Item contentStyle={{ display: "inline-grid" }}>
        <div style={{ fontWeight: "bold" }}>{row.mediopago_nombre}</div>
        {!(row.tipomediopago === PaymentMethodType.Efectivo) && (
          <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
            {row.tipomediopago_nombre}
          </span>
        )}
      </Descriptions.Item>
      <Descriptions.Item>{row?.nrooperacion}</Descriptions.Item>
      <Descriptions.Item contentStyle={{ display: "inline-grid" }}>
        <div style={{ fontWeight: "bold", textAlign: "end" }}>
          {parseNumeric(row?.monto).toFixed(2)}
        </div>
        <span style={{ fontSize: "10px", textAlign: "end" }}>
          {parseDateFormatFrontEnd(row?.fechapago)}
        </span>
      </Descriptions.Item>
      <Descriptions.Item
        style={{ width: "10%", paddingTop: "5px" }}
        contentStyle={{ justifyContent: "center" }}
      >
        <Dropdown menu={{ items: itemsMenu, onClick: handleMenuClick }}>
          <SettingOutlined />
        </Dropdown>
      </Descriptions.Item>
    </Descriptions>
  );
};
