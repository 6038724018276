import React, { useEffect } from "react";
import { CardUi } from "../../../../ui/CardUi";
import {
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  programacionTintoreriaClearActive,
  showFormProgramacionTintoreria,
} from "../../../../../actions/produccion";
import { FormItemHiddenUi } from "../../../../ui/FormItemHiddenUi";
import { ProveedorSelect } from "../../../reusable/ProveedorSelect";
import { DetalleProgramacionContent } from "./DetalleProgramacionContent";
import { EstadoProgramacionTag } from "../../reusable/EstadoProgramacionTag";
import { SwitchUi } from "../../../../ui/SwitchUi";

const items = ({ cantidadrollostotal, pesobrutototal, pesonetototal }) => [
  {
    key: "cantidadrollostotal",
    label: "Cantidad",
    children: <>{parseFloat(cantidadrollostotal).toFixed(2)}</>,
  },
  {
    key: "pesobrutototal",
    label: "Peso bruto total(KG)",
    children: <>{parseFloat(pesobrutototal).toFixed(2)}</>,
  },
  {
    key: "pesonetototal",
    label: "Peso neto total(KG)",
    children: <>{parseFloat(pesonetototal).toFixed(2)}</>,
  },
];

export const ProgramacionTintoreriaForm = () => {
  const form = Form.useFormInstance();
  const { activeProgramacionTintoreria } = useSelector(
    (state) => state.produccion
  );
  const detalleprogramaciontintoreria = Form.useWatch(
    "detalleprogramaciontintoreria",
    form
  );
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const cantidadrollostotal = Form.useWatch("cantidadrollostotal", form);
  const pesobrutototal = Form.useWatch("pesobrutototal", form);
  const pesonetototal = Form.useWatch("pesonetototal", form);
  const dispatch = useDispatch();
  useEffect(() => {
    let pesobrutototal = 0;
    let pesonetototal = 0;
    let cantidadrollostotal = 0;
    detalleprogramaciontintoreria?.forEach((x) => {
      pesobrutototal += parseFloat(x?.pesobruto) || 0;
      pesonetototal += parseFloat(x?.pesoneto) || 0;
      cantidadrollostotal += parseFloat(x?.cantidadrollos) || 0;
    });
    form.setFieldsValue({
      pesobrutototal: parseFloat(pesobrutototal).toFixed(2),
      pesonetototal: parseFloat(pesonetototal).toFixed(2),
      cantidadrollostotal,
    });
  }, [detalleprogramaciontintoreria]);

  const handleCancel = () => {
    dispatch(programacionTintoreriaClearActive());
    dispatch(showFormProgramacionTintoreria(false));
  };
  return (
    <CardUi
      title={
        <Space wrap>
          <span>Programación tintoreria</span>
          <EstadoProgramacionTag
            estado={
              activeProgramacionTintoreria
                ? activeProgramacionTintoreria?.estado
                : "nuevo"
            }
            estado_nombre={
              activeProgramacionTintoreria
                ? "Estado : " + activeProgramacionTintoreria?.nombreestado
                : "Estado : NUEVO"
            }
          />
        </Space>
      }
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleCancel}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormProgramacionTintoreria"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="cantidadrollostotal" />
      <FormItemHiddenUi name="pesobrutototal" />
      <FormItemHiddenUi name="pesonetototal" />
      <FormItemHiddenUi name="consolidadotela" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="proveedor"
                label="Proveedor"
                rules={[
                  { required: true, message: "El proveedor es obligatorio" },
                ]}
              >
                <ProveedorSelect placeholder="Seleccione proveedor" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Descriptions
                layout="vertical"
                size="small"
                items={items({
                  cantidadrollostotal,
                  pesobrutototal,
                  pesonetototal,
                })}
                colon={false}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="fechainicio"
                label="Fecha inicio"
                rules={[
                  {
                    required: true,
                    message: "La fecha de inicio es obligatorio",
                  },
                ]}
              >
                <DatePicker format={DateFormats.frontend} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fechafin"
                label="Fecha fin"
                rules={[
                  { required: true, message: "La fecha de fin es obligatoria" },
                ]}
              >
                <DatePicker format={DateFormats.frontend} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="tieneobservacion"
                label="Añadir observación"
                valuePropName="checked"
              >
                <SwitchUi />
              </Form.Item>
            </Col>
            {tieneobservacion && (
              <Col span={24}>
                <Form.Item name="observacion">
                  <Input.TextArea
                    placeholder="Ingrese observaciones"
                    style={{ width: "100%" }}
                    showCount
                    maxLength={250}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DetalleProgramacionContent />
        </Col>
      </Row>
    </CardUi>
  );
};
