import { Card, Col, Descriptions, Row, Form, Input, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { ArqueoCajaDetalleTable } from "./ArqueoCajaDetalleTable";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  PaymentMethodType,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { useDispatch, useSelector } from "react-redux";
import { ArqueoCajaModal } from "./modal/ArqueoCajaModal";
import { ArqueadoresCajaSelect } from "./reusable/ArqueadoresCajaSelect";
import { detalleArqueoCajaClearActive } from "../../../../actions/caja";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import dayjs from "dayjs";

const DescriptionItem_LabelStyle = {
  padding: "6px 10px",
};
const DescriptionItem_Style = {
  padding: "6px 10px",
};
export const ArqueoCajaStep = () => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const { activeCajaAbierta } = useSelector((state) => state.caja);
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen(true);
  const hideModal = () => {
    dispatch(detalleArqueoCajaClearActive());
    setOpen(false);
  };
  const detallearqueocaja = Form.useWatch("detallearqueocaja", form);
  useEffect(() => {
    const values = form.getFieldsValue();
    const _sum_montoreal = detallearqueocaja?.reduce((accumulator, object) => {
      return accumulator + object.total;
    }, 0);
    const _montoteorico = parseFloat(values?.montoteorico);
    const _montodescuadre = _sum_montoreal - _montoteorico || 0;
    form.setFieldValue("montoreal", parseFloat(_sum_montoreal).toFixed(2));
    form.setFieldValue(
      "montodescuadre",
      parseFloat(_montodescuadre).toFixed(2)
    );
  }, [detallearqueocaja]);

  return (
    <>
      <FormItemHiddenUi name="id" />
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Card title="Datos apertura caja" size="small">
            <Descriptions
              bordered
              column={{
                xxl: 1,
                xl: 1,
                lg: 1,
                md: 1,
                sm: 1,
                xs: 1,
              }}
            >
              <Descriptions.Item
                label="Cajero"
                labelStyle={DescriptionItem_LabelStyle}
                contentStyle={DescriptionItem_Style}
              >
                {activeCajaAbierta?.cajero_nombre}
              </Descriptions.Item>
              <Descriptions.Item
                label="Terminal"
                labelStyle={DescriptionItem_LabelStyle}
                contentStyle={DescriptionItem_Style}
              >
                {activeCajaAbierta?.nombreterminalcajero}
              </Descriptions.Item>
              <Descriptions.Item
                label="Turno"
                labelStyle={DescriptionItem_LabelStyle}
                contentStyle={DescriptionItem_Style}
              >
                {activeCajaAbierta?.nombreturno}
              </Descriptions.Item>
              <Descriptions.Item
                label="Fecha apertura"
                labelStyle={DescriptionItem_LabelStyle}
                contentStyle={DescriptionItem_Style}
              >
                {dayjs(activeCajaAbierta?.fechaapertura).format("DD/MM/YYYY")}
              </Descriptions.Item>
              <Descriptions.Item
                label="Fecha cierre"
                labelStyle={DescriptionItem_LabelStyle}
                contentStyle={DescriptionItem_Style}
              >
                <Form.Item name="fechacierre">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item
                label="Arqueador"
                style={DescriptionItem_Style}
              >
                <Form.Item
                  name="arqueador"
                  rules={[
                    {
                      required: true,
                      message: "El personal arqueador es obligatorio",
                    },
                  ]}
                  style={{ marginBottom: "8px" }}
                >
                  <ArqueadoresCajaSelect placeholder="Seleccione arqueador" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item
                label="Observación"
                style={DescriptionItem_Style}
              >
                <Form.Item name="observacion" style={{ marginBottom: "8px" }}>
                  <Input.TextArea rows={2} placeholder="Ingrese observación" />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Row style={{ paddingBottom: "8px" }}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Card title="Montos" size="small">
                <Descriptions
                  bordered
                  column={{
                    xxl: 1,
                    xl: 1,
                    lg: 1,
                    md: 1,
                    sm: 1,
                    xs: 1,
                  }}
                >
                  <Descriptions.Item
                    label="Monto arqueado"
                    labelStyle={DescriptionItem_LabelStyle}
                    contentStyle={DescriptionItem_Style}
                  >
                    <Form.Item name="montoreal">
                      <Input
                variant="borderless"
                        readOnly
                        style={{ textAlign: "end" }}
                        size="small"
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Monto descuadre"
                    labelStyle={DescriptionItem_LabelStyle}
                    contentStyle={DescriptionItem_Style}
                  >
                    <Form.Item name="montodescuadre">
                      <Input
                variant="borderless"
                        readOnly
                        style={{ textAlign: "end" }}
                        size="small"
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.List
                name="detallearqueocaja"
                rules={[
                  {
                    validator: async (_, value) => {
                      if (value < 1)
                        return Promise.reject(
                          new Error(
                            "Ingrese al menos un item en el detalle del arqueo de caja"
                          )
                        );
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <ArqueoCajaDetalleTable
                      detallearqueocaja={detallearqueocaja}
                      handleDelete={remove}
                      onOpenModal={showModal}
                      title={() => (
                        <ButtonUi
                          typeUi={TipoButtonConstants.AgregarTable}
                          onClick={() => showModal()}
                        />
                      )}
                    />
                    <Form.Item className="ui-errorlist">
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Col>
      </Row>
      <ArqueoCajaModal open={open} onCancelModal={hideModal} />
    </>
  );
};
