import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import React from "react";
import { messageError } from "../../../helpers/messageConfig";
import { download_FileObject } from "../../../helpers/utils";

const is_valid_file = (file) => {
  const file_type = String(file.name.split(".")[1]).toUpperCase();
  const files_accept = ["PDF", "JPG", "JPEG", "PNG"];
  const isValid = files_accept.includes(file_type);
  if (!isValid)
    messageError("Solo puede subir archivos válidos! (PDF,JPG,JPEG o PNG)");
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    messageError("File must smaller than 2MB!");
  }
  return isValid && isLt2M;
};

export const ArchivosComproCompraFileUpload = ({ name, archivos }) => {
  const form = Form.useFormInstance();
  const propsUpload = {
    accept: ".jpg,.jpeg,.png,application/pdf",
    multiple: true,
    beforeUpload: () => {
      return false;
    },
    onRemove: (file) => {
      const index = archivos.indexOf(file);
      const newFileList = archivos.slice();
      newFileList.splice(index, 1);
      form.setFieldValue(name, newFileList);
    },
    onChange: async ({ file, fileList: newFileList }) => {
      if (file?.status !== "removed") {
        const is_valida = is_valid_file(file);
        if (is_valida) {
          form.setFieldValue(name, newFileList);
        }
      }
    },
    onPreview: (file) => {
      if (file?.url) download_FileObject(file?.url, file?.name);
    },
    fileList: archivos,
  };
  return (
    <Upload {...propsUpload}>
      <Button icon={<UploadOutlined />}>Subir archivos</Button>
    </Upload>
  );
};
