import { Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import { TipoMedioPagoSelect } from "../../reusable/TipoMedioPagoSelect";
import { MedioPagoSelect } from "../../reusable/MedioPagoSelect";
import { PaymentMethodType } from "../../../../types/typesConstants";
import { DatePickerUi } from "../../../ui/DatePickerUi";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";

export const MediosPagoForm = () => {
  const form = Form.useFormInstance();
  const tipomediopago = Form.useWatch("tipomediopago", form);
  const prefijo = Form.useWatch("prefijo", form);
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
        <FormItemHiddenUi name="id" />
        <Form.Item name="tipomediopago" label="Tipo medio pago">
          <TipoMedioPagoSelect
            onChange={(value, field) =>
              form.setFieldsValue({
                mediopago: null,
                nrooperacion: null,
              })
            }
            style={{ width: "100%" }}
            placeholder="[-Seleccione-]"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="mediopago"
          label="Medio pago"
          hidden={!(tipomediopago !== PaymentMethodType.Efectivo)}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  getFieldValue("tipomediopago") !== PaymentMethodType.Efectivo
                ) {
                  if (!value)
                    return Promise.reject(
                      new Error("El medio pago es obligatorio")
                    );
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <MedioPagoSelect
            tipomediopago={tipomediopago}
            nameProps={{
              mediopago: "mediopago",
            }}
            onChange={(value, fieldMedio) =>
              form.setFieldsValue({
                nrooperacion: null,
              })
            }
            style={{ width: "100%" }}
            placeholder="[-Seleccione-]"
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name="nrooperacion"
          label="Número operación"
          hidden={!(tipomediopago !== PaymentMethodType.Efectivo)}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  getFieldValue("tipomediopago") !== PaymentMethodType.Efectivo
                ) {
                  if (!value)
                    return Promise.reject(
                      new Error("El número de operación es obligatorio")
                    );
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Ingrese N° Operación" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name="monto"
          label="Monto"
          rules={[
            { required: true, message: "el monto es obligatorio" },
            { type: "number", message: "Ingrese un número validó", min: 0.1 },
          ]}
        >
          <InputNumber
            placeholder="Ingrese monto"
            style={{ width: "100%" }}
            precision={2}
            addonBefore={prefijo}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="fechapago"
          label="Fecha"
          rules={[{ required: true, message: "la fecha es obligatoria" }]}
        >
          <DatePickerUi />
        </Form.Item>
      </Col>
    </Row>
  );
};
