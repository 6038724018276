import React from "react";
import { Descriptions, Space, Table, Tag, Tooltip } from "antd";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  EstadoConsolidadoTelaConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { TableUi } from "../../../ui/TableUi";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { parseNumeric } from "../../../../helpers/utils";

const columns = (props) => {
  const { handleEliminar } = props;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      fixed: "left",
      width: "3%",
      align: "center",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Item producido",
      dataIndex: "descripcion",
      key: "descripcion",
      fixed: "left",
      width: "20%",
    },
    {
      title: "Almacén destino",
      dataIndex: "almacen_nombre",
      key: "almacen_nombre",
      width: "10%",
    },
    {
      title: "Partida",
      dataIndex: "partida",
      key: "partida",
      align: "center",
    },

    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      align: "center",
    },
    {
      title: "Peso bruto(KG)",
      dataIndex: "pesobruto",
      key: "pesobruto",
      align: "right",
      render: (text) => <>{parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "Peso neto(KG)",
      dataIndex: "pesoneto",
      key: "pesoneto",
      align: "right",
      render: (text) => <>{parseFloat(text).toFixed(2)}</>,
    },
    {
      title: "Merma",
      dataIndex: "mermatintoreria",
      key: "mermatintoreria",
      align: "center",
      render: (text, record) => (
        <>
          {parseFloat(text).toFixed(2) + " KG"}
          <h5 style={{ margin: 0 }}>
            {record?.porcentajemermatintoreria + "%"}
          </h5>
        </>
      ),
    },
    {
      title: "Costo total ($)",
      dataIndex: "costototalbruto",
      key: "costototalbruto",
      align: "right",
      render: (text) => <>{parseNumeric(text).toFixed(2)}</>,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Costo total (S/)",
      dataIndex: "costototalbrutosoles",
      key: "costototalbrutosoles",
      align: "right",
      render: (text) => <>{parseNumeric(text).toFixed(2)}</>,
    },
    {
      title: "Costo KG (S/)",
      dataIndex: "costokgsoles",
      key: "costokgsoles",
      align: "right",
      render: (text) => <>{parseNumeric(text).toFixed(2)}</>,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      render: (value) =>
        value === EstadoConsolidadoTelaConstants.PROCESO ? (
          <Tag icon={<SyncOutlined spin />} color="processing">
            SIN PROCESAR
          </Tag>
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="success">
            FINALIZADO
          </Tag>
        ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      align: "center",
      render: (text, record, index) =>
        record?.estado === EstadoConsolidadoTelaConstants.PROCESO && (
          <Space>
            <Tooltip title="Eliminar">
              <ButtonUi
                typeUi={TipoButtonConstants.EliminarTable}
                size="small"
                onClick={() => handleEliminar(index)}
                displayText={false}
                ghost
              />
            </Tooltip>
          </Space>
        ),
    },
  ];
};

export const DetalleConsolidadoTelaTable = ({ data, remove, loading }) => {
  const handleEliminar = (rowIndex) => remove(rowIndex);
  return (
    <TableUi
      columns={columns({ handleEliminar })}
      rowKey="id"
      dataSource={data}
      size="small"
      loading={loading}
      virtual
      scroll={{
        x: 2000,
        y: 700,
      }}
      expandable={{
        expandedRowRender: (record) => (
          <Descriptions
            column={3}
            items={[
              {
                label: "Costo total tintoreria",
                children: parseFloat(record.costototaltintoreria).toFixed(2),
              },
              {
                label: "Costo total tejeduria",
                children: parseFloat(record.costototaltejeduria).toFixed(2),
              },
              {
                label: "Costo total insumo/materia prima",
                children: parseFloat(record.costototalinsumo).toFixed(2),
              },
            ]}
            contentStyle={{ paddingBottom: 0, fontWeight: "bold" }}
          />
        ),
      }}
    />
  );
};
