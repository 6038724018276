import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../ui/ModalUi";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { DetalleEntradaSerieForm } from "./DetalleEntradaSerieForm";
import { Form } from "antd";
import { FormUi } from "../../ui/FormUi";
import { DetalleEntradaLoteForm } from "./DetalleEntradaLoteForm";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const initialValues = {
  series: [],
  lotes: [],
  manejalotes: false,
  manejaseries: false,
};
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};

export const DetalleSeriesLotesModal = ({
  manejalotes,
  manejaseries,
  cantidad,
  series,
  lotes,
  open,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { activeDetalleComprobanteMovimiento } = useSelector(
    (state) => state.inventario
  );
  useResetFormOnCloseModal({ form, open });
  useEffect(() => {
    if (open) {
      if (activeDetalleComprobanteMovimiento) {
        //editar
        form.setFieldsValue(activeDetalleComprobanteMovimiento);
      }
      if (cantidad > 0) {
        if (manejaseries) {
          const item = {
            numero_serie: "",
            fecha_fabricacion: dayjs(),
          };
          const count_new_items = cantidad - series?.length || 0;
          for (let i = 0; i < count_new_items; i++) {
            series.push(item);
          }
          form.setFieldsValue({ series });
        } else if (manejalotes) {
          const item_lote = {
            numero_lote: null,
            cantidad: null,
            fecha_fabricacion: dayjs(),
            fecha_vencimiento: dayjs(),
          };
          if (lotes?.length < 1) {
            lotes.push(item_lote);
          }
          form.setFieldsValue({ lotes });
        }
      }
    }
  }, [cantidad, open]);

  const handleSave = () => {
    onCancel();
  };

  return (
    <ModalUi
      title="Detalle producto por lote/serie"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="FormDetalleSerieLote"
        />,
      ]}
    >
      <FormUi
        name="FormDetalleSerieLote"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        {manejalotes && <DetalleEntradaLoteForm cantidad={cantidad} />}
        {manejaseries && (
          <DetalleEntradaSerieForm cantidad={cantidad} openModal={open} />
        )}
      </FormUi>
    </ModalUi>
  );
};
