import React, { useState } from "react";
import { TableSearchBasicUi } from "../../ui/TableSearchBasicUi";
import { parseDateFormatFrontEnd, parseNumeric } from "../../../helpers/utils";
import { Table, Tag } from "antd";
import { EstadoSerieConstants } from "../../../types/typesConstants";
const expand_lote_columns = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  { title: "Lote", dataIndex: "numero_lote", key: "numero_lote" },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    render: (value) => <>{parseNumeric(value).toFixed(2)}</>,
  },
  {
    title: "F.Fabricación",
    dataIndex: "fecha_fabricacion",
    key: "fecha_fabricacion",
  },
];
const expand_serie_columns = [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  { title: "Serie", dataIndex: "numero_serie", key: "numero_serie" },
  {
    title: "F.Fabricación",
    dataIndex: "fecha_fabricacion",
    key: "fecha_fabricacion",
    render: (value) => <>{parseDateFormatFrontEnd(value)}</>,
  },
  {
    title: "F.Vencimiento",
    dataIndex: "fecha_vencimiento",
    key: "fecha_vencimiento",
    render: (value) => <>{parseDateFormatFrontEnd(value)}</>,
  },
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    render: (value, record) => (
      <>
        {value === EstadoSerieConstants.EN_STOCK && (
          <Tag color="success">{record?.estadonombre}</Tag>
        )}
        {value === EstadoSerieConstants.VENDIDO && (
          <Tag color="default">{record?.estadonombre}</Tag>
        )}
      </>
    ),
  },
];

const columns = (opts) => [
  {
    title: "#",
    dataIndex: "nro",
    key: "nro",
    render: (text, record, index) => <>{index + 1}</>,
  },
  {
    title: "Almacén",
    dataIndex: "almacennombre",
    key: "almacennombre",
    fixed: "left",
    width: "60%",
    filteredValue: [opts?.filteredColumn],
    onFilter: (value, record) =>
      String(record.almacennombre).toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Stock",
    dataIndex: "stockactual",
    key: "stockactual",
    width: "20%",
    render: (value) => <>{parseNumeric(value).toFixed(2)}</>,
  },
  {
    title: "U.Medida",
    dataIndex: "unidadmedidaprefijosunat",
    key: "unidadmedidaprefijosunat",
    align: "center",
  },
  Table.EXPAND_COLUMN,
];
const expandedRowRender = (data) => {
  if (data?.manejalotes || data?.manejaseries) {
    return (
      <Table
        columns={data?.manejalotes ? expand_lote_columns : expand_serie_columns}
        rowKey="id"
        dataSource={data?.manejalotes ? data?.lotes : data?.series}
        pagination={false}
        size="small"
      />
    );
  }
};

export const StockInventariosTable = ({ inventarios = [] }) => {
  const [searchText, setSearchText] = useState("");
  const onSearch = (value, e) => {
    e.preventDefault();
    setSearchText(value);
  };
  return (
    <TableSearchBasicUi
      columns={columns({ filteredColumn: searchText })}
      rowKey="id"
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => {
          if (record?.manejalotes || record?.manejaseries) return true;
          else return false;
        },
      }}
      data={inventarios}
      onSearch={onSearch}
      colSpanSeach={14}
      size="small"
    />
  );
};
