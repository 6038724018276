import React, { useEffect, useRef, useState } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { Form } from "antd";
import { FormUi } from "../../../ui/FormUi";
import { ItemDetalleForm } from "./ItemDetalleForm";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { movimientoClearActive } from "../../../../actions/inventario";

const initialValues = (data = {}) => ({
  id: data?.id || new dayjs().valueOf(),
  inventario: data?.inventario || null,
  item_nombre: data?.item_nombre || null,
  cantidad: parseInt(data?.cantidad) || 1,
  almacen: data?.almacen || null,
  manejaseries: data?.manejaseries || false,
  manejalotes: data?.manejalotes || false,
  series: data?.series || [],
  lotes: data?.lotes || [],
  preciounitario: data?.preciounitario || null,
  valorunitario: data?.valorunitario || null,
  valorigvunitario: data?.valorigvunitario || 0,
  porcentajedisplay: data?.porcentajedisplay || 0,
  porcentajeimpuesto: data?.porcentajeimpuesto || 0,
  valorigv: data?.valorigv || 0,
  unidadmedida: data?.unidadmedida || null,
  unidadmedida_nombre: data?.unidadmedida_nombre || null,
  descuentos: data?.descuentos || 0,
  grupotipoafectacionigv: data?.grupotipoafectacionigv || "Ope. Gravada",
  disabledafectacionigv: data?.disabledafectacionigv || false,
});
// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({
  form,
  open,
  activeDetalleComprobantePago,
}) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      dispatch(movimientoClearActive());
      form.resetFields();
    } else {
      //Abrir Modal
      if (activeDetalleComprobantePago) {
        form.setFieldsValue({
          ...activeDetalleComprobantePago,
          cantidad: parseInt(activeDetalleComprobantePago.cantidad),
        });
      } else {
        form.setFieldsValue({ id: new dayjs().valueOf() });
      }
    }
  }, [form, prevOpen, open]);
};
export const ItemDetalleModal = ({ open, onCancelModal }) => {
  const [form] = Form.useForm();

  const { activeDetalleComprobantePago } = useSelector((state) => state.venta);
  const { activeEmpleado } = useSelector((state) => state.auth);
  const [cajaabierta, setCajaabierta] = useState(
    JSON.parse(localStorage.getItem("caja")) || null
  );
  useResetFormOnCloseModal({
    form,
    open,
    activeDetalleComprobantePago,
  });
  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        porcentajedisplay:
          "IGV " +
          parseFloat(activeEmpleado?.empresa?.porcentajeigv).toFixed(0) +
          " %",
        porcentajeimpuesto: activeEmpleado?.empresa?.porcentajeigv,
        almacen: cajaabierta?.local,
      });
    }
  }, [form, open]);
  return (
    <ModalUi
      title="Busqueda de item/servicio"
      open={open}
      onCancel={onCancelModal}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="ItemDetalleForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        name="ItemDetalleForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeDetalleComprobantePago)}
      >
        <ItemDetalleForm />
      </FormUi>
    </ModalUi>
  );
};
