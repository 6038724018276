import { Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormUi } from "../../ui/FormUi";
import { ComprobanteCompraForm } from "./ComprobanteCompraForm";
import { ItemDetalleModal } from "../modal/ItemDetalleModal";
import dayjs from "dayjs";
import { CondicionPago, DateFormats } from "../../../types/typesConstants";
import {
  comprobantecompraClearActive,
  comprobantecompraStartAdd,
  comprobantecompraStartUpdate,
  detallecomprobantecompraClearActive,
  showFormComprobanteCompra,
} from "../../../actions/compra";
import { to_Base64 } from "../../../helpers/utils";

const initialValues = (data = {}) => ({
  id: data?.id || 0,
  tipocomprobante: data?.tipocomprobante || null,
  nrocomprobante: data?.nrocomprobante || null,
  fechaemision: dayjs(data?.fechaemision) || dayjs(),
  fechavencimiento: dayjs(data?.fechavencimiento) || dayjs(),
  proveedor: data?.proveedor || null,
  proveedor_nombre: data?.proveedor_nombre || null,
  moneda: data?.moneda || null,
  monedaprefijo: data?.monedaprefijo || null,
  tipocambio: data?.tipocambio || 0,
  tienepagos: data?.tienepagos || false,
  condicionpago: data?.condicionpago || CondicionPago.CONTADO,
  mediospago: data?.mediospago || [],
  tieneobservacion: data?.tieneobservacion || false,
  observacion: data?.observacion || null,
  detallescomprobantecompra: data?.detallescomprobantecompra || [],
  empresa: data?.empresa || null,
  archivo: data?.archivo || "",
  tienedetraccion: data?.tienedetraccion ? true : false,
  montodetraccion: data?.montodetraccion || 0,
  montototalgravadas: data?.montototalgravadas || null,
  montototalexoneradas: data?.montototalexoneradas || null,
  montototalinafectas: data?.montototalinafectas || null,
  montoigvtotal: data?.montoigvtotal || null,
  montototal: data?.montototal || null,
  montototal_monedabase: data?.montototal_monedabase || null,
  archivos: data?.archivos || [],
});

export const ComprobanteCompraScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeComprobanteCompra, activeDetalleComprobanteCompra } =
    useSelector((state) => state.compra);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    dispatch(comprobantecompraClearActive());
    dispatch(showFormComprobanteCompra(false));
  };
  const handleSubmit = async (values) => {
    let FileList = [];
    let index = 0;
    for await (const item of values?.archivos) {
      if (!item?.id) {
        FileList.push({
          id: dayjs().valueOf() + index,
          archivo:
            item?.originFileObj !== undefined
              ? await to_Base64(item?.originFileObj)
              : item.url || "",
          tiponivel: "SECUND",
        });
        index++;
      } else FileList = [...FileList, { ...item, archivo: item?.url }];
    }

    const valuesData = {
      ...values,
      fechaemision: dayjs(values.fechaemision).format(DateFormats.backend),
      fechavencimiento: dayjs(values.fechavencimiento).format(
        DateFormats.backend
      ),
      empresa: activeEmpleado?.empresa?.id,
      archivos: FileList,
    };
    if (activeComprobanteCompra) {
      dispatch(comprobantecompraStartUpdate(valuesData));
    } else {
      dispatch(comprobantecompraStartAdd(valuesData));
    }
    handleCancel();
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormComprobanteCompra } = forms;
        if (name === "ItemDetalleForm") {
          const _valorcompra = parseFloat(
            values?.preciounitario * values?.cantidad
          );
          const _valorcomprabruto = values?.cantidad * values?.valorunitario;
          const valuesData = {
            ...values,
            valorigv: parseFloat(
              parseFloat(values?.cantidad * values?.valorigvunitario).toFixed(2)
            ),
            valorcomprabruto: parseFloat(
              parseFloat(_valorcomprabruto).toFixed(4)
            ),
            valorcompra: parseFloat(
              parseFloat(_valorcompra - values?.descuentos).toFixed(4)
            ),
          };
          const detallescomprobantecompra =
            FormComprobanteCompra.getFieldValue("detallescomprobantecompra") ||
            [];
          if (activeDetalleComprobanteCompra) {
            const _detallecomprobantesCompra = detallescomprobantecompra.map(
              (e) => (e.id === valuesData.id ? valuesData : e)
            );
            FormComprobanteCompra.setFieldsValue({
              detallescomprobantecompra: _detallecomprobantesCompra,
            });
          } else {
            FormComprobanteCompra.setFieldsValue({
              detallescomprobantecompra: [
                ...detallescomprobantecompra,
                valuesData,
              ],
            });
          }
          dispatch(detallecomprobantecompraClearActive());
          hideModal();
        }
      }}
    >
      <FormUi
        name="FormComprobanteCompra"
        form={form}
        initialValues={initialValues(activeComprobanteCompra)}
        handleSubmit={handleSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <ComprobanteCompraForm
          onOpenModal={showModal}
          handleCancelForm={handleCancel}
        />
      </FormUi>
      <ItemDetalleModal open={open} onCancelModal={hideModal} />
    </Form.Provider>
  );
};
