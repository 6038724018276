import { Descriptions } from "antd";
import React from "react";
import { parseNumeric } from "../../../../helpers/utils";
import dayjs from "dayjs";
import { DateFormats } from "../../../../types/typesConstants";

const items = (comprobantepago) => {
  const cantidadTotal = comprobantepago?.detallescomprobantepago?.reduce(
    (a, b) => a + parseFloat(b.cantidad),
    0
  );
  return [
    {
      key: "total",
      label: "TOTAL",
      children: `${parseNumeric(comprobantepago?.montototal).toFixed(2)} ${
        comprobantepago?.moneda_prefijosunat
      }`,
      labelStyle: { fontSize: "16px", fontWeight: "bold" },
      contentStyle: { fontSize: "16px", fontWeight: "bold" },
    },
    {
      key: "nrocomprobante",
      label: "Número de comprobante",
      children: `${comprobantepago?.comprobante}`,
      labelStyle: { fontSize: "12px" },
      contentStyle: { fontSize: "12px" },
    },
    {
      key: "cliente",
      label: "Cliente",
      children: `${comprobantepago?.cliente_nombre}`,
      labelStyle: { fontSize: "12px" },
      contentStyle: { fontSize: "12px" },
    },
    {
      key: "fechaemision",
      label: "Fecha",
      children: `${dayjs(comprobantepago?.fechaemision).format(
        DateFormats.frontend
      )}`,
      labelStyle: { fontSize: "12px" },
      contentStyle: { fontSize: "12px" },
    },
    {
      key: "vencimiento",
      label: "Vencimiento",
      children: `${dayjs(comprobantepago?.fechavencimiento).format(
        DateFormats.frontend
      )}`,
      labelStyle: { fontSize: "12px" },
      contentStyle: { fontSize: "12px" },
    },
    {
      key: "totalcantidades",
      label: "Total unidades",
      children: `${parseNumeric(cantidadTotal).toFixed(2)}`,
      labelStyle: { fontSize: "12px" },
      contentStyle: { fontSize: "12px" },
    },
  ];
};
export const ComproPagoResultHeaderPart1 = ({ comprobantepago }) => {
  return (
    <Descriptions
      column={1}
      items={items(comprobantepago)}
      size="small"
      colon={false}
      labelStyle={{ width: "50%" }}
      contentStyle={{ justifyContent: "end" }}
    />
  );
};
