import React, { useEffect } from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  programacionTintoreriaStartLoading,
  showFormProgramacionTintoreria,
} from "../../../../../actions/produccion";
import { ProgramacionTintoreriaTable } from "./ProgramacionTintoreriaTable";
import { ProgramacionTintoreriaScreen } from "./ProgramacionTintoreriaScreen";
import { ConsolidadoTelaScreen } from "../../consolidado/ConsolidadoTelaScreen";
import { ProgramacionTintoreriaSearchTable } from "./ProgramacionTintoreriaSearchTable";

const ProgramacionTintoteriaListScreen = () => {
  const dispatch = useDispatch();
  const { programacionesTintoreria, loading } = useSelector(
    (state) => state.produccion
  );
  const handleNuevo = () => {
    dispatch(showFormProgramacionTintoreria());
  };

  return (
    <PageHeaderUi
      title="Programación de Tintoreria"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <ProgramacionTintoreriaSearchTable />
          </Col>
        </Row>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ProgramacionTintoreriaTable
              data={programacionesTintoreria}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ProgramacionTintoreriaList = () => {
  const { visibleFormProgramacionTintoreria, visibleFormConsolidadoTela } =
    useSelector((state) => state.produccion);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(programacionTintoreriaStartLoading());
  }, []);
  if (visibleFormProgramacionTintoreria) {
    return <ProgramacionTintoreriaScreen />;
  } else if (visibleFormConsolidadoTela) {
    return <ConsolidadoTelaScreen />;
  } else {
    return <ProgramacionTintoteriaListScreen />;
  }
};
export default ProgramacionTintoreriaList;
