import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesVenta";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { uiShowLoading } from "./ui";

const key = "msgterminal";
const routes = { terminalcajero: "terminalcajero/" };
export const terminalesCajeroStartLoad = (filter_type = null, query = null) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    try {
      const resp = await axiosConToken.get(`${routes.terminalcajero}`, {
        params: { filter_type, query },
      });
      const data = resp.data;
      dispatch(terminalesCajeroLoaded(data));
    } catch (error) {
      validaMessagesErrors(error, key);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const terminalCajeroStartAdd = (terminalcajero) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        `${routes.terminalcajero}`,
        terminalcajero
      );
      const { msg, terminalcajero: datanew } = resp.data;
      dispatch(terminalCajeroAdded(datanew));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const terminalCajeroStartUpdate = (terminalcajero) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.terminalcajero}${terminalcajero.id}/`,
        terminalcajero
      );
      const { msg, terminalcajero: dataupdate } = resp.data;
      dispatch(terminalCajeroUpdated(dataupdate));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
export const terminalCajeroStartDelete = (terminalcajero) => {
  return async (dispatch) => {
    dispatch(uiShowLoading());
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(
        `${routes.terminalcajero}${terminalcajero.id}/`
      );
      const { msg } = resp.data;
      dispatch(terminalCajeroDeleted(terminalcajero));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    } finally {
      dispatch(uiShowLoading(false));
    }
  };
};
const terminalesCajeroLoaded = (terminales) => ({
  type: types.terminalcajeroLoaded,
  payload: terminales,
});
const terminalCajeroAdded = (terminalcajero) => ({
  type: types.terminalcajeroAdded,
  payload: terminalcajero,
});
const terminalCajeroUpdated = (terminalcajero) => ({
  type: types.terminalcajeroUpdated,
  payload: terminalcajero,
});
const terminalCajeroDeleted = (terminalcajero) => ({
  type: types.terminalcajeroDeleted,
  payload: terminalcajero,
});
export const terminalCajeroSetActive = (terminalcajero) => ({
  type: types.terminalcajeroSetActive,
  payload: terminalcajero,
});
export const terminalCajeroClearActive = () => ({
  type: types.terminalcajeroClearActive,
});
export const get_terminalescajero = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.terminalcajero}get_terminalescajero/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipoemisiones = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.terminalcajero}get_tipoemisiones/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipooperaciones = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.terminalcajero}get_tipooperaciones/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_tipocomprobantes_by_tipooperacion_and_tipoemision = async (
  tipooperacion,
  tipoemision
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.terminalcajero}get_tipocomprobantes_by_tipooperacion_and_tipoemision/`,
      { params: { tipooperacion, tipoemision } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

const getEmpleados = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.terminalcajero}get_empleados/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export { getEmpleados };
