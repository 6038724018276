import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesGenerales";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";

const routes = {
  empresas: "empresas/",
};

export const empresasStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.empresas);
      const data = resp.data;
      dispatch(empresasLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};
export const empresaStartAdd = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.empresas, data);
      const { msg, empresa } = resp.data;
      dispatch(empresaAdded(empresa));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const empresaStartUpdate = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(
        `${routes.empresas}${data.id}/`,
        data
      );
      const { msg, empresa } = resp.data;
      dispatch(empresaUpdated(empresa));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
export const empresaStartDelete = (data) => {
  return async (dispatch) => {
    const msgloading = messageLoading("Eliminando...");
    try {
      const resp = await axiosConToken.delete(`${routes.empresas}${data.id}/`);
      const { msg } = resp.data;
      dispatch(empresaDeleted(data));
      messageSuccessLoading(msgloading, msg);
    } catch (error) {
      validaMessagesErrors(error, msgloading);
    }
  };
};
const empresasLoaded = (clientes) => ({
  type: types.empresaLoaded,
  payload: clientes,
});
const empresaAdded = (cliente) => ({
  type: types.empresaAdded,
  payload: cliente,
});
const empresaUpdated = (cliente) => ({
  type: types.empresaUpdated,
  payload: cliente,
});
const empresaDeleted = (cliente) => ({
  type: types.empresaDeleted,
  payload: cliente,
});
export const empresaSetActive = (cliente) => ({
  type: types.empresaSetActive,
  payload: cliente,
});
export const empresaClearActive = () => ({ type: types.empresaClearActive });

export const get_soap_tipos = async () => {
  let data = null;
  try {
    const resp = await axiosConToken.get(`${routes.empresas}get_soap_types/`);
    data = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return data;
};
