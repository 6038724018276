import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../../../ui/ModalUi";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
import { FormUi } from "../../../../../ui/FormUi";
import { Form } from "antd";
import { DetalleComprobanteMovimientoForm } from "./DetalleComprobanteMovimientoForm";
const initialValues = {
  detalleprogramaciontejido: null,
  detallecomprobantemovimiento: null,
  precio: null,
  moneda: null,
  moneda_prefijo: null,
  tipocambio: null,
};
const useResetFormOnCloseModal = ({ form, open }) => {
  const prevOpenRef = useRef();
  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;
  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, open]);
};
export const DetalleComprobanteMovimientoModal = ({
  detalleprogramaciontejido,
  open,
  onCancelModal,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (open)
      form.setFieldValue(
        "detalleprogramaciontejido",
        detalleprogramaciontejido
      );
  }, [open, detalleprogramaciontejido]);
  useResetFormOnCloseModal({ form, open });
  const handleSave = (values) => onCancelModal();
  return (
    <ModalUi
      title="Busqueda de movimiento de inventario para producción"
      open={open}
      onCancel={onCancelModal}
      width="50%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form={`DetalleComprobanteMovimientoForm${detalleprogramaciontejido}`}
          // form="DetalleComprobanteMovimientoForm"
        />,
      ]}
      forceRender
    >
      <FormUi
        // name="DetalleComprobanteMovimientoForm"
        name={`DetalleComprobanteMovimientoForm${detalleprogramaciontejido}`}
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <DetalleComprobanteMovimientoForm open={open} />
      </FormUi>
    </ModalUi>
  );
};
