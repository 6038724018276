import React from "react";
import { MovimientoEntradaForm } from "./MovimientoEntradaForm";
import { Form } from "antd";
import { FormUi } from "../../../ui/FormUi";
import { messageError } from "../../../../helpers/messageConfig";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DateFormats } from "../../../../types/typesConstants";
import {
  comprobanteMovimientoClearActive,
  comprobanteMovimientoEntradaStartAdd,
  showFormInputMovements,
} from "../../../../actions/inventario";

const initialValues = (data) => ({
  id: data?.id || null,
  tipocomprobantemovimiento: data?.tipocomprobantemovimiento || null,
  seriecomprobantemovimiento: data?.seriecomprobantemovimiento || null,
  nrocomprobantemovimiento: data?.nrocomprobantemovimiento || null,
  tieneobservacion: data?.length > 0 ? true : false,
  observacion: data?.observacion || null,
  almacendestino: data?.almacendestino || null,
  almacenorigen: data?.almacenorigen || null,
  tipomovimiento: data?.tipomovimiento || null,
  comprobantecompra: data?.comprobantecompra || null,
  detallescomprobantemovimiento: data?.detallescomprobantemovimiento || [],
});

export const MovimientoEntradaScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeComprobanteMovimiento, activeDetalleComprobanteMovimiento } =
    useSelector((state) => state.inventario);
  const navigate = useNavigate();
  const handleSubmit = (values) => {
    values = {
      ...values,
      almacendestino: values?.almacenorigen,
      empresa: activeEmpleado?.empresa?.id,
      detallescomprobantemovimiento: values?.detallescomprobantemovimiento?.map(
        (item) => ({
          ...item,
          lotes: item?.lotes?.map((x) => ({
            ...x,
            fecha_fabricacion: dayjs(x?.fecha_fabricacion).format(
              DateFormats.backend
            ),
            fecha_vencimiento: dayjs(x?.fecha_vencimiento).format(
              DateFormats.backend
            ),
          })),
          series: item?.series?.map((x) => ({
            ...x,
            fecha_fabricacion: dayjs(x.fecha_fabricacion).format(
              DateFormats.backend
            ),
          })),
        })
      ),
    };

    if (!activeComprobanteMovimiento?.id && !values?.id) {
      //Nuevo
      dispatch(comprobanteMovimientoEntradaStartAdd(values));
    }
    handleCloseForm();
  };
  const handleCloseForm = () => {
    const RedirectTo = localStorage.getItem("ComprobanteCompraForm") || false;
    if (RedirectTo) {
      navigate("/compras/comprobante");
      localStorage.removeItem("ComprobanteCompraForm");
    }
    dispatch(comprobanteMovimientoClearActive());
    dispatch(showFormInputMovements(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormMovimientoEntrada, ItemDetalleForm } = forms;
        if (name === "ItemDetalleForm") {
          const detallescomprobantemovimiento =
            FormMovimientoEntrada.getFieldValue(
              "detallescomprobantemovimiento"
            ) || [];
          const _existe = detallescomprobantemovimiento?.filter(
            (x) => x.item === values?.item
          )?.length;
          if (activeDetalleComprobanteMovimiento) {
            FormMovimientoEntrada.setFieldsValue({
              detallescomprobantemovimiento: detallescomprobantemovimiento.map(
                (e) => (e.id === values?.id ? values : e)
              ),
            });
          } else {
            if (_existe < 1)
              FormMovimientoEntrada.setFieldsValue({
                detallescomprobantemovimiento: [
                  ...detallescomprobantemovimiento,
                  values,
                ],
              });
            else messageError("el item ya existe en el detalle del movimiento");
          }
        }
        if (name === "FormDetalleSerieLote") {
          const series = values?.series;
          console.log(series);
          const valuesLotes = values?.lotes?.filter((e) => e.cantidad !== null);
          ItemDetalleForm.setFieldsValue({ series, lotes: valuesLotes });
        }
      }}
    >
      <FormUi
        name="FormMovimientoEntrada"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeComprobanteMovimiento)}
        handleSubmit={handleSubmit}
      >
        <MovimientoEntradaForm HandleCloseForm={handleCloseForm} />
      </FormUi>
    </Form.Provider>
  );
};
