import React from "react";
import { CardUi } from "../../ui/CardUi";
import { Button, Checkbox, Col, Divider, Form, Row } from "antd";
import { SwitchUi } from "../../ui/SwitchUi";
import { ImageUploadUi2 } from "../../ui/ImageUploadUi2";
import { UsuariosSelect } from "./reusable/UsuariosSelect";

export const AdicionalesCard = () => {
  const form = Form.useFormInstance();
  const { setFieldValue } = form;
  const image = Form.useWatch("image", form);
  const tieneaccesosistema = Form.useWatch("tieneaccesosistema", form);
  const generarusuario = Form.useWatch("generarusuario", form);
  return (
    <CardUi title="Adicionales" size="small" type="inner">
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Form.Item name="image">
            <ImageUploadUi2 name="image" imageList={image} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item name="state" label="¿Está activo?" valuePropName="checked">
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="tieneaccesosistema"
            label="¿Tiene acceso al sistema?"
            valuePropName="checked"
          >
            <SwitchUi
              onChange={() => {
                setFieldValue("usuario", null);
                setFieldValue("generarusuario", false);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="esarqueadorcaja"
            label="¿Es arqueador de caja?"
            valuePropName="checked"
          >
            <SwitchUi />
          </Form.Item>
        </Col>
      </Row>
      <Divider
        style={{ marginBottom: "4px", marginTop: "0px" }}
        orientation="left"
      >
        Datos de Usuario
      </Divider>
      <Row>
        <Col span={12}>
          <Form.Item
            name="generarusuario"
            valuePropName="checked"
            hidden={!tieneaccesosistema}
          >
            <Checkbox
              onChange={() => {
                setFieldValue("usuario", null);
              }}
            >
              ¿Generar usuario?
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="usuario"
            label="Usuario"
            hidden={
              generarusuario ||
              (tieneaccesosistema && generarusuario) ||
              !(tieneaccesosistema || (tieneaccesosistema && generarusuario))
            }
            rules={[
              {
                required: !generarusuario,
                message: "El usuario es obligatorio",
              },
            ]}
          >
            <UsuariosSelect placeholder="[-Seleccione-]" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="link">Configurar usuario</Button>
        </Col>
      </Row>
    </CardUi>
  );
};
