import { Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ComprobantePrintModal } from "../../reusable/ComprobantePrintModal";
import { ComprobantePagoModal } from "./ComprobantePagoModal";
import { NotaVentaSeachFormTable } from "./NotaVentaSeachFormTable";
import { NotaVentaTable } from "./NotaVentaTable";
import { PageHeaderUi } from "../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ComprobantePagoScreen } from "../ComprobantePagoScreen";
import { ComprobantePagoResultForm } from "../ComprobantePagoResultForm";

const NotaVentaListScreen = ({ handleOpenForm }) => {
  const { comprobantespago, loading } = useSelector(
    (state) => state.comprobantepago
  );

  return (
    <PageHeaderUi
      title="Notas de venta"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleOpenForm}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <NotaVentaSeachFormTable />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={32} md={32} lg={32} xl={32}>
            <NotaVentaTable comprobantes={comprobantespago} loading={loading} />
          </Col>
        </Row>
      </div>
      <ComprobantePagoModal />
      <ComprobantePrintModal />
    </PageHeaderUi>
  );
};

export const NotaVentaList = () => {
  const { ComprobantePagoResultVisibleForm, ComprobantePagoVisibleForm } =
    useSelector((state) => state.comprobantepago);
  const [showForm, setShowForm] = useState(false);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);
  if (ComprobantePagoVisibleForm)
    return <ComprobantePagoScreen handleCloseForm={handleCloseForm} />;
  else if (ComprobantePagoResultVisibleForm)
    return <ComprobantePagoResultForm />;
  else return <NotaVentaListScreen handleOpenForm={handleOpenForm} />;
};

export default NotaVentaList;
