import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { search_proveedores_by_name_or_code } from "../../../actions/proveedor";

export const ProveedorDropdown = ({
  name,
  nameSearchText,
  label = " ",
  fieldsFormSet = null,
  ...restProps
}) => {
  const form = Form.useFormInstance();
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await search_proveedores_by_name_or_code(value);
      const options = data.map((item) => ({
        label: item?.label,
        key: item?.value,
      }));
      setItems(options);
      setItemsData(data);
    }
  };
  const onClick = ({ key }) => {
    const itemSelect = itemsData?.find((x) => x.value === parseInt(key));
    let newValues = {};
    if (fieldsFormSet) {
      for (const [key, value] of Object.entries(fieldsFormSet)) {
        if (key in itemSelect) {
          newValues[key] = itemSelect[key];
        }
      }
    }
    newValues[name] = itemSelect?.value;
    newValues[nameSearchText] = itemSelect?.label;
    form.setFieldsValue({ ...newValues });
  };
  const handleChangeSearch = () => {
    let newValues = {};
    if (fieldsFormSet) {
      for (const [key, value] of Object.entries(fieldsFormSet)) {
        newValues[key] = null;
      }
    }
    newValues[name] = null;
    form.setFieldsValue({ ...newValues });
  };
  return (
    <Dropdown menu={{ items, onClick }}>
      <Form.Item name={nameSearchText} label={label}>
        <Input.Search
          placeholder={restProps?.placeholder}
          allowClear
          enterButton
          onSearch={handleSearch}
          onChange={handleChangeSearch}
        />
      </Form.Item>
    </Dropdown>
  );
};
