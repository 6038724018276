import { Form, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  comprobantepagoByNotaVentaCreate,
  comprobantepagoClearActive,
  showModalComprobanteOptionPrint,
  ShowModalComprobantePago,
} from "../../../../actions/comprobante";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { ComprobantePagoForm } from "./ComprobantePagoForm";
import dayjs from "dayjs";
import { FormUi } from "../../../ui/FormUi";
const initialValues = {
  tipocomprobante: null,
  seriecomprobante: null,
  observaciones: null,
  formapago: null,
  vendedor: null,
  fechaemision: dayjs(),
  fechavencimiento: dayjs(),
};
// const validationSchema = Yup.object().shape({
//   tipocomprobante: Yup.string().required("Seleccione tipo comprobante"),
//   seriecomprobante: Yup.string().required("Seleccione serie comprobante"),
//   formapago: Yup.string().required("Seleccione forma pago"),
//   vendedor: Yup.string().required("Seleccione vendedor"),
//   fechaemision: Yup.string()
//     .required("La Fecha de emisión es obligatoria")
//     .nullable(),
//   fechavencimiento: Yup.string()
//     .required("La Fecha de vencimiento es obligatoria")
//     .nullable(),
// });
export const ComprobantePagoModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { visibleModal, activeComprobantePago } = useSelector(
    (state) => state.comprobantepago
  );
  const { activeCajaAbierta } = useSelector((state) => state.venta);
  const handleSave = (values) => {
    values = {
      ...values,
      fechaemision: dayjs(values.fechaemision).format("YYYY-MM-DD"),
      fechavencimiento: dayjs(values.fechavencimiento).format("YYYY-MM-DD"),
      terminalcajero: activeCajaAbierta?.terminalcajero,
      aperturacaja: activeCajaAbierta?.id,
    };
    dispatch(
      comprobantepagoByNotaVentaCreate(activeComprobantePago?.id, values)
    );
    dispatch(showModalComprobanteOptionPrint());
  };
  const handleCancel = () => {
    dispatch(comprobantepagoClearActive());
    dispatch(ShowModalComprobantePago(false));
  };
  return (
    <Modal
      title={`${activeComprobantePago?.tipocomprobante_nombre} referencia: ${activeComprobantePago?.comprobante}`}
      centered
      maskClosable={false}
      destroyOnClose
      open={visibleModal}
      onOk={handleSave}
      onCancel={handleCancel}
      width="60%"
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancel}
        />,
        <ButtonUi
          key="submit"
          htmlType="submit"
          form="FormComprobantePago"
          typeUi={TipoButtonConstants.Guardar}
        />,
      ]}
    >
      <FormUi
        form={form}
        initialValues={initialValues}
        handleSubmit={handleSave}
      >
        <ComprobantePagoForm />
      </FormUi>
    </Modal>
  );
};
