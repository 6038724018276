import {
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  Row,
  Space,
} from "antd";

import React, { useEffect, useState } from "react";
// import { AlmacenSelect } from "../../reusable/AlmacenSelect";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { DetalleSerieModal } from "./DetalleSerieModal";
import { DetalleLoteModal } from "./DetalleLoteModal";
import { UnidadMedidaSelect } from "../../../generales/unidadesmedida/UnidadMedidaSelect";
import { InputSearchDropdown } from "../../reusable/ItemSearchDropdown";
import { useSelector } from "react-redux";
import {
  fnCalculoPrecioUnitario,
  fnCalculoValorUnitario,
  parseNumeric,
} from "../../../../helpers/utils";
import { TipoAfectacionIGV } from "../../../compras/reusable/TipoAfectacionIGV";
import { DescuentoPart } from "./DescuentoPart";

const items = ({
  grupotipoafectacionigv,
  preciounitario,
  cantidad,
  valorunitario,
  valorigvunitario,
  descuentos,
}) => {
  return [
    {
      key: 1,
      label: grupotipoafectacionigv,
      children: parseNumeric(cantidad * valorunitario).toFixed(2),
    },
    {
      key: 2,
      label: "IGV",
      children: parseNumeric(cantidad * valorigvunitario).toFixed(2),
    },
    {
      key: 3,
      label: "Descuento",
      children: parseNumeric(descuentos).toFixed(2),
    },
    {
      key: 4,
      label: "Importe total",
      children: parseNumeric(cantidad * preciounitario - descuentos).toFixed(2),
    },
  ];
};

export const ItemDetalleForm = () => {
  const form = Form.useFormInstance();
  const disabledafectacionigv = Form.useWatch("disabledafectacionigv", form);
  const grupotipoafectacionigv = Form.useWatch("grupotipoafectacionigv", form);
  const cantidad = Form.useWatch("cantidad", form);
  const preciounitario = Form.useWatch("preciounitario", form);
  const porcentajeimpuesto = Form.useWatch("porcentajeimpuesto", form);
  const valorunitario = Form.useWatch("valorunitario", form);
  const valorigvunitario = Form.useWatch("valorigvunitario", form);
  const descuentos = Form.useWatch("descuentos", form);
  const manejalotes = Form.useWatch("manejalotes", form);
  const manejaseries = Form.useWatch("manejaseries", form);
  // const almacen = Form.useWatch("almacen", form);
  const { activeCajaAbierta } = useSelector((state) => state.caja);
  const [open, setOpen] = useState(false);
  const [openLoteModal, setOpenLoteModal] = useState(false);

  const hideModal = () => {
    setOpen(false);
  };
  const hideLoteModal = () => {
    setOpenLoteModal(false);
  };
  const handleShowModal = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleShowLoteModal = () => {
    form
      .validateFields(["cantidad"])
      .then((response) => {
        setOpenLoteModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    const { valorigvunitario, valorunitario } = fnCalculoValorUnitario(
      preciounitario,
      porcentajeimpuesto
    );
    form.setFieldsValue({ valorigvunitario, valorunitario });
  }, [preciounitario]);

  const handleChangeValorUnitario = (value) => {
    const { valorigvunitario, preciounitario } = fnCalculoPrecioUnitario(
      value,
      porcentajeimpuesto
    );
    form.setFieldsValue({ valorigvunitario, preciounitario });
  };
  // const handleChangePrecioUnitario = (value) => {
  //   const { valorigvunitario, valorunitario } = fnCalculoValorUnitario(
  //     value,
  //     porcentajeimpuesto
  //   );
  //   form.setFieldsValue({ valorigvunitario, valorunitario });
  // };
  const handleChangeEditarAfectacionIGV = () => {
    form.setFieldValue("disabledafectacionigv", !disabledafectacionigv);
  };
  const handleChangeAfectacionIGV = (value, field) => {
    form.setFieldValue("grupotipoafectacionigv", field?.grupo_label);
  };
  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="item" />
      <FormItemHiddenUi name="almacen_nombre" />
      <FormItemHiddenUi name="almacen" />
      <FormItemHiddenUi name="unidadmedida_nombre" />
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <FormItemHiddenUi name="inventario" />
      <FormItemHiddenUi name="lotes" />
      <FormItemHiddenUi name="series" />
      <FormItemHiddenUi name="valorigv" />
      <FormItemHiddenUi name="porcentajeimpuesto" />
      <FormItemHiddenUi name="grupotipoafectacionigv" />
      <FormItemHiddenUi name="descuentos" />
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item
            name="almacen"
            label="Almacén"
            rules={[{ required: true, message: "El almacén es obligatorio" }]}
          >
            <AlmacenSelect
              placeholder="[-Selecione-]"
              onChange={onChangeAlmacen}
              disabled
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <InputSearchDropdown
            almacen={activeCajaAbierta?.local}
            label="Item"
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} xl={3}>
          <Form.Item name="stockactual" label="Stock Actual">
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              placeholder="0.00"
              readOnly
              variant="borderless"
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
          <Form.Item label=" ">
            {manejaseries && (
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar series"
                onClick={handleShowModal}
              />
            )}
            {manejalotes && (
              <ButtonUi
                typeUi={TipoButtonConstants.Agregar}
                text="Agregar lotes"
                onClick={handleShowLoteModal}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: "5px 0" }} />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={7} xl={5}>
          <Form.Item
            name="cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              {
                type: "number",
                min: 0.01,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} precision={2} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item
            name="unidadmedida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect
              placeholder="[-Unidad medida-]"
              // onChange={onChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={7}>
          <Form.Item
            name="tipoafectacionigv"
            rules={[
              {
                required: true,
                message: "El tipo de afectación es obligatoria",
              },
            ]}
          >
            <TipoAfectacionIGV
              placeholder="[-Afectación IGV-]"
              disabled={!disabledafectacionigv}
              onChange={handleChangeAfectacionIGV}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={2} xl={1}>
          <Form.Item name="disabledafectacionigv" valuePropName="checked">
            <Checkbox onChange={handleChangeEditarAfectacionIGV}>
              Editar
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={7} xl={5}>
          <Form.Item
            name="valorunitario"
            rules={[{ required: true, message: "Ingrese el valor unitario" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              onChange={handleChangeValorUnitario}
              placeholder="Valor unitario"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          <Space.Compact>
            <Form.Item
              name="porcentajedisplay"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Porcentaje es requerido",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "55%",
                }}
                precision={0}
                readOnly={true}
              />
            </Form.Item>
            <Form.Item
              name="valorigvunitario"
              noStyle
              rules={[
                {
                  required: true,
                  message: "El valor igv is required",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "45%",
                }}
                precision={2}
                placeholder="IGV"
                disabled
              />
            </Form.Item>
          </Space.Compact>
        </Col>
        <Col xs={24} sm={24} md={12} lg={7} xl={5}>
          <Form.Item
            name="preciounitario"
            rules={[{ required: true, message: "Ingrese el precio unitario" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              // onChange={handleChangePrecioUnitario}
              placeholder="Precio unitario"
            />
          </Form.Item>
        </Col>
      </Row>
      <DescuentoPart />
      <Divider orientation="right" style={{ margin: "4px 0" }}>
        Totales
      </Divider>
      <Row justify="end">
        <Col xs={24} sm={24} md={16} lg={12} xl={10}>
          <Descriptions
            items={items({
              grupotipoafectacionigv,
              preciounitario,
              cantidad,
              valorunitario,
              valorigvunitario,
              descuentos,
            })}
            layout="horizontal"
            bordered
            column={1}
            size="small"
            labelStyle={{
              textAlign: "end",
              width: "60%",
              padding: "4px 8px",
              backgroundColor: "#fff",
              fontWeight: "bold",
            }}
            contentStyle={{ textAlign: "end", padding: "4px 8px" }}
          />
        </Col>
      </Row>
      {manejaseries && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="series"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (manejaseries) {
                      const cantSeleccionada = value?.filter(
                        (item) => item.selected === true
                      ).length;
                      if (cantSeleccionada !== getFieldValue("cantidad"))
                        return Promise.reject(
                          new Error(
                            "Las series deben ser igual a la cantidad ingresada"
                          )
                        );
                    }
                  },
                }),
              ]}
            >
              {(fields, options, { errors }) => (
                <>
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      {manejalotes && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <Form.List
              name="lotes"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    if (manejalotes) {
                      const suma = value?.reduce(
                        (prev, curr) =>
                          Number(prev) + Number(curr?.cantidad_ingresada),
                        0
                      );
                      if (suma !== getFieldValue("cantidad"))
                        return Promise.reject(
                          new Error(
                            "La cantidad de los lotes deben ser igual a la cantidad ingresada"
                          )
                        );
                    }
                  },
                }),
              ]}
            >
              {(fields, options, { errors }) => (
                <>
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      )}
      <DetalleSerieModal
        open={open}
        onCancelModal={hideModal}
        cantidad={cantidad}
        series={form.getFieldValue("series")}
      />
      <DetalleLoteModal
        open={openLoteModal}
        onCancelModal={hideLoteModal}
        cantidad={cantidad}
        lotes={form.getFieldValue("lotes")}
      />
    </>
  );
};
