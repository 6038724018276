import React, { useEffect, useRef } from "react";
import { ModalUi } from "../../../ui/ModalUi";
import {
  PaymentMethodType,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { ButtonUi } from "../../../ui/ButtonUi";
import { FormUi } from "../../../ui/FormUi";
import { Form } from "antd";
import { MediosPagoForm } from "./MediosPagoForm";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  mediopagoByComprobantePagoStartAdd,
  mediopagocomprobantepagoClearActive,
} from "../../../../actions/comprobante";
import { parseDateFormatBackEnd } from "../../../../helpers/utils";

const initialValues = {
  tipomediopago: PaymentMethodType.Efectivo,
  mediopago: null,
  nrooperacion: null,
  monto: 0,
  fechapago: dayjs(),
};
const useResetFormOnCloseModal = ({
  form,
  open,
  activeMedioPagoComprobantePago,
}) => {
  const dispatch = useDispatch();
  const prevOpenRef = useRef();

  useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    form.resetFields();
    if (!open && prevOpen) {
      dispatch(mediopagocomprobantepagoClearActive());
    } else {
      if (activeMedioPagoComprobantePago) {
        form.setFieldsValue({
          ...activeMedioPagoComprobantePago,
          fechapago: dayjs(activeMedioPagoComprobantePago?.fechapago),
        });
      } else {
        form.setFieldValue("id", new dayjs().valueOf());
      }
    }
  }, [form, prevOpen, open]);
};
export const MediosPagoModal = ({ open, handleCancelModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeComprobantePago, activeMedioPagoComprobantePago } = useSelector(
    (state) => state.comprobantepago
  );
  useResetFormOnCloseModal({
    form,
    open,
    activeMedioPagoComprobantePago,
  });
  const handleSubmit = (values) => {
    values = {
      ...values,
      fechapago: parseDateFormatBackEnd(values?.fechapago),
      comprobante: activeComprobantePago?.id,
    };
    dispatch(mediopagoByComprobantePagoStartAdd(values));
    handleCancelModal();
  };
  return (
    <ModalUi
      title="Nuevo pago"
      open={open}
      onCancel={handleCancelModal}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={handleCancelModal}
        />,
        <ButtonUi
          key="submit"
          typeUi={TipoButtonConstants.Guardar}
          htmlType="submit"
          form="MediosPagoForm"
        />,
      ]}
    >
      <FormUi
        name="MediosPagoForm"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <MediosPagoForm />
      </FormUi>
    </ModalUi>
  );
};
