import React, { useEffect } from "react";
import { FormUi } from "../../ui/FormUi";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { useDispatch } from "react-redux";
import { terminalesCajeroStartLoad } from "../../../actions/terminalcajero";
import {
  DateFormats,
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { parseDateFormatBackEnd } from "../../../helpers/utils";
import { SelectUi } from "../../ui/SelectUi";
import { ButtonUi } from "../../ui/ButtonUi";
const initialValues = {
  filter_type: null,
  nombre: null,
  fechaemision: null,
};
const options = [
  {
    value: FiltersSearch_Types.NOMBRE,
    label: "Nombre",
  },
  {
    value: FiltersSearch_Types.FECHAEMISION,
    label: "Fecha registro",
  },
];
export const TerminalCajeroSearchTable = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const filter_type = Form.useWatch("filter_type", form);
  useEffect(() => {
    dispatch(terminalesCajeroStartLoad());
  }, []);

  const handleSubmit = (values) => {
    let query = null;
    if (filter_type === FiltersSearch_Types.NOMBRE) query = values?.nombre;
    else if (filter_type === FiltersSearch_Types.FECHAEMISION)
      query = parseDateFormatBackEnd(values?.fechaemision);
    dispatch(terminalesCajeroStartLoad(values?.filter_type, query));
  };

  const OnChangeFilterType = () => {
    if (filter_type === FiltersSearch_Types.NOMBRE)
      form.setFieldsValue({ fechaemision: null });
    else if (filter_type === FiltersSearch_Types.FECHAEMISION)
      form.setFieldsValue({ nombre: null });
  };
  return (
    <FormUi
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item name="filter_type" label="Filtrar por">
            <SelectUi
              allowClear
              placeholder="[-TODOS-]"
              data={options}
              onChange={OnChangeFilterType}
            />
          </Form.Item>
        </Col>
        {filter_type === FiltersSearch_Types.NOMBRE && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item
              name="nombre"
              rules={[
                {
                  required: filter_type === FiltersSearch_Types.NOMBRE,
                  message: "Ingrese nombre",
                },
              ]}
            >
              <Input placeholder="Ingrese nombre" />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.FECHAEMISION && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item
              name="fechaemision"
              rules={[
                {
                  required: filter_type === FiltersSearch_Types.FECHAEMISION,
                  message: "Ingrese fecha",
                },
              ]}
            >
              <DatePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
    </FormUi>
  );
};
