import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { ButtonUi } from "../ui/ButtonUi";
import {
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../types/typesConstants";
import { FormUi } from "../ui/FormUi";
import { CategoriaProductoSelect } from "./reusable/CategoriaProductoSelect";
import { ProveedorSelect } from "./reusable/ProveedorSelect";
import { productoStartLoading } from "../../actions/producto";
import { useDispatch } from "react-redux";

const initialValues = {
  filter_type: FiltersSearch_Types.NOMBRE,
  nombre: "",
  categoria: null,
  proveedor: null,
};
const options = [
  {
    value: FiltersSearch_Types.NOMBRE,
    label: "Nombre",
  },
  {
    value: FiltersSearch_Types.CATEGORIA,
    label: "Categoria",
  },
  {
    value: FiltersSearch_Types.PROVEEDOR,
    label: "Proveedor",
  },
];
export const ProductoSearchFormTable = () => {
  const [form] = Form.useForm();
  const filter_type = Form.useWatch("filter_type", form);
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    dispatch(
      productoStartLoading(
        values?.filter_type,
        values?.nombre || values?.categoria || values?.proveedor
      )
    );
  };
  return (
    <FormUi
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row>
        <Col xs={24} sm={24} md={8} lg={6} xl={6}>
          <Form.Item name="filter_type" label="Filtrar por">
            <Select placeholder="Filtrar por" options={options} />
          </Form.Item>
        </Col>
        {filter_type === FiltersSearch_Types.NOMBRE && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="nombre">
              <Input placeholder="Ingrese nombre" />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.CATEGORIA && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="categoria">
              <CategoriaProductoSelect placeholder="Seleccione categoria" />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.PROVEEDOR && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item name="proveedor">
              <ProveedorSelect placeholder="Seleccione proveedor" />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
    </FormUi>
  );
};
