import { Button, Col, Descriptions, Row } from "antd";
import React, { useState } from "react";
import { MediosPagoContentItem } from "./MediosPagoContentItem";
import { MediosPagoModal } from "./MediosPagoModal";

export const ComproPagoMediosPagoPart = ({ mediospago }) => {
  const [openModalMedioPago, setOpenModalMedioPago] = useState(false);
  const handleOpenModalMedioPago = () => setOpenModalMedioPago(true);
  const handleCloseModalMedioPago = () => setOpenModalMedioPago(false);
  return (
    <>
      <Descriptions
        column={1}
        items={[
          {
            key: "Title",
            label: "Pagos",
            children: (
              <Button type="link" onClick={handleOpenModalMedioPago}>
                Nuevo pago
              </Button>
            ),
          },
        ]}
        colon={false}
        labelStyle={{ width: "50%" }}
        contentStyle={{ justifyContent: "end" }}
      />
      <Row>
        <Col span={24}>
          {mediospago?.map((row) => (
            <MediosPagoContentItem
              key={row?.id}
              row={row}
              handleOpenModal={handleOpenModalMedioPago}
            />
          ))}
        </Col>
      </Row>
      <MediosPagoModal
        open={openModalMedioPago}
        handleCancelModal={handleCloseModalMedioPago}
      />
    </>
  );
};
