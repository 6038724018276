import { SendOutlined } from "@ant-design/icons";
import { Button, Descriptions, Input, Space } from "antd";
import React, { useState } from "react";
import { messageError } from "../../../../helpers/messageConfig";
import { validateEmail } from "../../../../helpers/utils";
import { comprobantespagoSendEmail } from "../../../../actions/comprobante";
import { useDispatch } from "react-redux";

export const ComproPagoSendEmailPart = ({ comprobantepago }) => {
  const dispatch = useDispatch();
  const handleEnviarEmail = (value, _e, info) => {
    _e.preventDefault();
    const email = value.trim();
    if (value && email.length > 0 && validateEmail(email))
      dispatch(comprobantespagoSendEmail(comprobantepago?.id, email));
    else messageError("Ingrese un email válido");
  };

  return (
    <Descriptions
      column={1}
      items={[
        {
          key: "email",
          label: "Email",
          children: (
            <>
              <Input.Search
                allowClear
                onSearch={handleEnviarEmail}
                style={{ width: "100%" }}
                enterButton={
                  <>
                    <SendOutlined /> Enviar
                  </>
                }
              />
            </>
          ),
        },
      ]}
      colon={false}
      labelStyle={{ width: "30%" }}
      contentStyle={{ justifyContent: "end", width: "70%" }}
    />
  );
};
