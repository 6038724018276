import React from "react";
import { FormUi } from "../../../../ui/FormUi";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ProgramacionTintoreriaForm } from "./ProgramacionTintoreriaForm";
import {
  detalleProgramacionTintoreriaClearActive,
  programacionTintoreriaClearActive,
  programacionTintoreriaStartAdd,
  programacionTintoreriaStartUpdate,
  showFormProgramacionTintoreria,
} from "../../../../../actions/produccion";
import dayjs from "dayjs";
import { EstadoProgramacionTejido } from "../../../../../types/typesConstants";
import { parseDateFormatBackEnd } from "../../../../../helpers/utils";
const initialValues = (data = {}) => ({
  id: data?.id || dayjs().valueOf(),
  cantidadrollostotal: data?.cantidadrollostotal || 0,
  pesobrutototal: data?.pesobrutototal || 0,
  pesonetototal: data?.pesonetototal || 0,
  proveedor: data?.proveedor || null,
  fechainicio: dayjs(data?.fechainicio) || dayjs(),
  fechafin: dayjs(data?.fechafin) || dayjs(),
  tieneobservacion: data?.tieneobservacion?.length > 0 || null,
  observacion: data?.observacion || null,
  detalleprogramaciontintoreria: data?.detalleprogramaciontintoreria || [
    {
      id: new Date().getTime(),
      nombretipotela: null,
      color: null,
      cantidadrollos: null,
      pesobruto: null,
      pesoneto: null,
      tipotenidotela: null,
      anchotela: null,
      observacion: null,
      estado: EstadoProgramacionTejido.PROCESO,
      detalleprogramaciontejido: null,
    },
  ],
  estado: data?.estado || null,
  consolidadotela: data?.consolidadotela || null,
});

export const ProgramacionTintoreriaScreen = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeProgramacionTintoreria, activeDetalleProgramacionTintoreria } =
    useSelector((state) => state.produccion);

  const handleSave = (values) => {
    const valuesData = {
      ...values,
      empresa: activeEmpleado?.empresa?.id,
      fechainicio: parseDateFormatBackEnd(values?.fechainicio),
      fechafin: parseDateFormatBackEnd(values?.fechafin),
    };
    if (activeProgramacionTintoreria) {
      dispatch(programacionTintoreriaStartUpdate(valuesData));
    } else {
      dispatch(programacionTintoreriaStartAdd(valuesData));
    }
    dispatch(showFormProgramacionTintoreria(false));
    dispatch(programacionTintoreriaClearActive());
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === "FormSearchProgramacionTejido") {
          const { FormProgramacionTintoreria } = forms;
          const data = {
            ...activeDetalleProgramacionTintoreria,
            detalleprogramaciontejido: values?.detalleprogramaciontejido?.id,
            detalleprogramaciontejido_obj: values?.detalleprogramaciontejido,
            detalleprogramaciontejido_nombre:
              values?.detalleprogramaciontejido?.descripcioncorta,
            detalleprogramaciontejido_cantidad:
              values?.detalleprogramaciontejido?.cantidad,
            detalleprogramaciontejido_peso:
              values?.detalleprogramaciontejido?.peso,
          };
          let detalleprogramaciontintoreria =
            FormProgramacionTintoreria.getFieldValue(
              "detalleprogramaciontintoreria"
            ) || [];
          FormProgramacionTintoreria.setFieldsValue({
            detalleprogramaciontintoreria: detalleprogramaciontintoreria.map(
              (e) => (e.id === data.id ? data : e)
            ),
          });
          dispatch(detalleProgramacionTintoreriaClearActive());
        }
        if (name === "FormComprobanteCompra") {
          const { FormProgramacionTintoreria } = forms;
          const data = {
            ...activeDetalleProgramacionTintoreria,
            detallecomprobantecompra: values?.detallecomprobantecompra?.id,
            detallecomprobantecompra_nombre:
              values?.detallecomprobantecompra?.descripcion_label,
            detallecomprobantecompra_preciounidad:
              values?.detallecomprobantecompra?.preciounitario,
            precio: values?.detallecomprobantecompra?.preciounitario,
            moneda: values?.detallecomprobantecompra?.moneda,
            tipocambio: values?.detallecomprobantecompra?.tipocambio,
          };
          let detalleprogramaciontintoreria =
            FormProgramacionTintoreria.getFieldValue(
              "detalleprogramaciontintoreria"
            ) || [];
          FormProgramacionTintoreria.setFieldsValue({
            detalleprogramaciontintoreria: detalleprogramaciontintoreria.map(
              (e) => (e.id === data.id ? data : e)
            ),
          });
          dispatch(detalleProgramacionTintoreriaClearActive());
        }
      }}
    >
      <FormUi
        name="FormProgramacionTintoreria"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeProgramacionTintoreria)}
        handleSubmit={handleSave}
      >
        <ProgramacionTintoreriaForm />
      </FormUi>
    </Form.Provider>
  );
};
