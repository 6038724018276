import React from "react";
import { ModalUi } from "../../ui/ModalUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { StockInventariosTable } from "./StockInventariosTable";

export const StockInventariosModal = ({ inventarios, open, onCancel }) => {
  return (
    <ModalUi
      title="Stock en inventarios"
      centered
      maskClosable={false}
      destroyOnClose
      open={open}
      onCancel={onCancel}
      footer={[
        <ButtonUi
          key="back"
          typeUi={TipoButtonConstants.Cancelar}
          onClick={onCancel}
        />,
      ]}
    >
      <StockInventariosTable inventarios={inventarios} />
    </ModalUi>
  );
};
