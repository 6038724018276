import { Col, DatePicker, Form, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NotasVentaStartLoad } from "../../../../actions/comprobante";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { SelectUi } from "../../../ui/SelectUi";
import { parseDateFormatBackEnd } from "../../../../helpers/utils";
import { ClienteSelect } from "../../reusable/ClienteSelect";
import { FormUi } from "../../../ui/FormUi";

const initialValues = {
  filter_type: null,
  fechaemision: null,
  cliente: null,
};
const options = [
  {
    value: FiltersSearch_Types.FECHAEMISION,
    label: "Fecha emisión",
  },
  {
    value: FiltersSearch_Types.CLIENTE,
    label: "Cliente",
  },
];
export const NotaVentaSeachFormTable = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const filter_type = Form.useWatch("filter_type", form);
  const handleSubmit = (values) => {
    let query = null;
    if (filter_type === FiltersSearch_Types.FECHAEMISION)
      query = parseDateFormatBackEnd(values?.fechaemision);
    else if (filter_type === FiltersSearch_Types.CLIENTE)
      query = values?.cliente;
    dispatch(NotasVentaStartLoad(filter_type, query));
  };
  useEffect(() => {
    dispatch(NotasVentaStartLoad());
  }, [dispatch]);
  const OnChangeFilterType = () => {
    if (filter_type === FiltersSearch_Types.FECHAEMISION)
      form.setFieldsValue({ cliente: null });
    else if (filter_type === FiltersSearch_Types.CLIENTE)
      form.setFieldsValue({ fechaemision: null });
    else form.setFieldValue({ fechaemision: null, cliente: null });
  };
  return (
    <FormUi
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      form={form}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
          <Form.Item name="filter_type" label="Filtrar por">
            <SelectUi
              allowClear
              placeholder="[-TODOS-]"
              data={options}
              onChange={OnChangeFilterType}
            />
          </Form.Item>
        </Col>
        {filter_type === FiltersSearch_Types.FECHAEMISION && (
          <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
            <Form.Item
              name="fechaemision"
              rules={[
                {
                  required: filter_type === FiltersSearch_Types.FECHAEMISION,
                  message: "Ingrese fecha emisión",
                },
              ]}
            >
              <DatePicker name="fechaemision" format={"DD-MM-YYYY"} />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.CLIENTE && (
          <Col xs={12} sm={12} md={8} lg={6} xl={6} offset={1}>
            <Form.Item name="cliente">
              <ClienteSelect allowClear placeholder="[-Clientes varios-]" />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
    </FormUi>
  );
};
