import React from "react";
import { FormUi } from "../../../ui/FormUi";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConsolidadoTelaForm } from "./ConsolidadoTelaForm";
import dayjs from "dayjs";
import {
  DateFormats,
  EstadoConsolidadoTelaConstants,
} from "../../../../types/typesConstants";
import {
  consolidadoTelaClearActive,
  consolidadoTelaStartAdd,
  consolidadoTelaStartUpdate,
  showFormConsolidadoTela,
} from "../../../../actions/produccion";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  programaciontintoreria: data?.programaciontintoreria || null,
  codigo: data?.codigo || null,
  proveedor_ruc: data?.proveedor_ruc || null,
  proveedor_nombre: data?.proveedor_nombre || null,
  fechatintoreria: dayjs(data?.fechatintoreria) || dayjs(),
  cantidadtotal: parseFloat(data?.cantidadtotal) || null,
  pesobrutototal: data?.pesobrutototal || null,
  pesonetototal: data?.pesonetototal || null,
  fechaconsolidacion: dayjs(data?.fechaconsolidacion) || dayjs(),
  detalleconsolidadotela: data?.detalleconsolidadotela || [],
  estado: data?.estado || EstadoConsolidadoTelaConstants.PROCESO,
});
export const ConsolidadoTelaScreen = () => {
  const [form] = Form.useForm();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { activeConsolidadoTela } = useSelector((state) => state.produccion);
  const dispatch = useDispatch();
  const handleSave = (values) => {
    values = {
      ...values,
      fechaconsolidacion: dayjs(values?.fechaconsolidacion).format(
        DateFormats.backend
      ),
      empresa: activeEmpleado?.empresa?.id,
    };
    if (activeConsolidadoTela?.id)
      //Actualizar
      dispatch(consolidadoTelaStartUpdate(values));
    //Nuevo
    else dispatch(consolidadoTelaStartAdd(values));

    handleClose();
  };
  const handleClose = () => {
    dispatch(consolidadoTelaClearActive());
    dispatch(showFormConsolidadoTela(false));
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormConsolidadoTela } = forms;
        if (name === "FormDetalleProgramacionTintoreria") {
          const valuesData = values?.detalleprogramaciontintoreria?.map(
            (x, index) => ({
              ...x,
              detalleprogramaciontintoreria: x.id,
              id: dayjs().valueOf() + index,
              estado: EstadoConsolidadoTelaConstants.PROCESO,
            })
          );
          const { detalleconsolidadotela } =
            FormConsolidadoTela.getFieldsValue();
          const valuesNewAdd = valuesData.filter(
            (x) =>
              !detalleconsolidadotela.some(
                (item) =>
                  item.detalleprogramaciontintoreria ===
                  x.detalleprogramaciontintoreria
              )
          );
          console.log(detalleconsolidadotela);
          console.log("add   --- ", valuesNewAdd);
          const allData = detalleconsolidadotela.concat(valuesNewAdd);
          FormConsolidadoTela.setFieldValue("detalleconsolidadotela", allData);
        }
      }}
    >
      <FormUi
        name="FormConsolidadoTela"
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues(activeConsolidadoTela)}
        handleSubmit={handleSave}
      >
        <ConsolidadoTelaForm handleClose={handleClose} />
      </FormUi>
    </Form.Provider>
  );
};
