import {
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  Row,
  Space,
} from "antd";

import React, { useEffect } from "react";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { UnidadMedidaSelect } from "../reusable/UnidadMedidaSelect";
import { useSelector } from "react-redux";
import { InputSearchDropdown } from "../reusable/InputSearchDropdown";
import { TipoAfectacionIGV } from "../reusable/TipoAfectacionIGV";
import {
  fnCalculoPrecioUnitario,
  fnCalculoValorUnitario,
} from "../../../helpers/utils";

const items = ({
  grupotipoafectacionigv,
  preciounitario,
  cantidad,
  valorunitario,
  valorigvunitario,
}) => {
  return [
    {
      key: 1,
      label: grupotipoafectacionigv,
      children: parseFloat(cantidad * valorunitario).toFixed(4),
    },
    {
      key: 2,
      label: "IGV",
      children: parseFloat(cantidad * valorigvunitario).toFixed(4),
    },
    {
      key: 3,
      label: "Importe total",
      children: parseFloat(cantidad * preciounitario).toFixed(4),
    },
  ];
};

export const ItemDetalleForm = () => {
  const form = Form.useFormInstance();
  const disabledafectacionigv = Form.useWatch("disabledafectacionigv", form);
  const grupotipoafectacionigv = Form.useWatch("grupotipoafectacionigv", form);
  const porcentajeimpuesto = Form.useWatch("porcentajeimpuesto", form);
  const preciounitario = Form.useWatch("preciounitario", form);
  const cantidad = Form.useWatch("cantidad", form);
  const valorunitario = Form.useWatch("valorunitario", form);
  const valorigvunitario = Form.useWatch("valorigvunitario", form);
  const { activeEmpleado } = useSelector((state) => state.auth);

  const onChangeUnidadMedida = (value, field) => {
    form.setFieldsValue({ unidadmedida_nombre: field?.prefijo });
  };
  const handleChangeValorUnitario = (value) => {
    const { valorigvunitario, preciounitario } = fnCalculoPrecioUnitario(
      value,
      porcentajeimpuesto
    );
    form.setFieldsValue({ valorigvunitario, preciounitario });
  };
  const handleChangePrecioUnitario = (value) => {
    const { valorigvunitario, valorunitario } = fnCalculoValorUnitario(
      value,
      porcentajeimpuesto
    );
    form.setFieldsValue({ valorigvunitario, valorunitario });
  };
  const handleChangeEditarAfectacionIGV = () => {
    form.setFieldValue("disabledafectacionigv", !disabledafectacionigv);
  };
  const handleChangeAfectacionIGV = (value, field) => {
    form.setFieldValue("grupotipoafectacionigv", field?.grupo_label);
  };
  useEffect(() => {
    if (grupotipoafectacionigv !== "OPE. GRAVADA") {
      form.setFieldsValue({
        porcentajedisplay: "IGV 0%",
        porcentajeimpuesto: 0,
        valorigvunitario: 0,
        valorunitario: preciounitario,
      });
    } else {
      const porcentajeimpuesto = activeEmpleado?.empresa?.porcentajeigv;
      const { valorigvunitario, valorunitario } = fnCalculoValorUnitario(
        preciounitario,
        porcentajeimpuesto
      );
      form.setFieldsValue({
        porcentajedisplay:
          "IGV " +
          parseFloat(activeEmpleado?.empresa?.porcentajeigv).toFixed(0) +
          "%",
        porcentajeimpuesto,
        valorigvunitario,
        valorunitario,
      });
    }
  }, [grupotipoafectacionigv]);

  return (
    <>
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="tipoafectacionigv" />
      <FormItemHiddenUi name="item" />
      <FormItemHiddenUi name="descuentos" />
      <FormItemHiddenUi name="almacen_nombre" />
      <FormItemHiddenUi name="unidadmedida_nombre" />
      <FormItemHiddenUi name="esinventariado" />
      <FormItemHiddenUi name="manejalotes" />
      <FormItemHiddenUi name="manejaseries" />
      <FormItemHiddenUi name="valorigv" />
      <FormItemHiddenUi name="porcentajeimpuesto" />
      <FormItemHiddenUi name="grupotipoafectacionigv" />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <InputSearchDropdown />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={6} xl={5}>
          <Form.Item
            name="cantidad"
            rules={[
              { required: true, message: "La cantidad es obligatorio" },
              {
                type: "number",
                min: 0.01,
                message: "Ingrese un número válido",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} precision={2} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item
            name="unidadmedida"
            rules={[
              { required: true, message: "La unidad de medida es obligatoria" },
            ]}
          >
            <UnidadMedidaSelect
              placeholder="[-Unidad medida-]"
              onChange={onChangeUnidadMedida}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item
            name="tipoafectacionigv"
            rules={[
              {
                required: true,
                message: "El tipo de afectación es obligatoria",
              },
            ]}
          >
            <TipoAfectacionIGV
              placeholder="[-Afectación IGV-]"
              disabled={!disabledafectacionigv}
              onChange={handleChangeAfectacionIGV}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={2} xl={1}>
          <Form.Item name="disabledafectacionigv" valuePropName="checked">
            <Checkbox onChange={handleChangeEditarAfectacionIGV}>
              Editar
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={7} xl={5}>
          <Form.Item
            name="valorunitario"
            rules={[{ required: true, message: "Ingrese el valor unitario" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={4}
              onChange={handleChangeValorUnitario}
              placeholder="Valor unitario"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          <Space.Compact>
            <Form.Item
              name="porcentajedisplay"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Porcentaje es requerido",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "55%",
                }}
                precision={0}
                readOnly={true}
              />
            </Form.Item>
            <Form.Item
              name="valorigvunitario"
              noStyle
              rules={[
                {
                  required: true,
                  message: "El valor igv is required",
                },
              ]}
            >
              <InputNumber
                style={{
                  width: "45%",
                }}
                precision={4}
                placeholder="IGV"
                disabled
              />
            </Form.Item>
          </Space.Compact>
        </Col>
        <Col xs={24} sm={24} md={12} lg={7} xl={5}>
          <Form.Item
            name="preciounitario"
            rules={[{ required: true, message: "Ingrese el precio unitario" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={4}
              onChange={handleChangePrecioUnitario}
              placeholder="Precio unitario"
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left" style={{ margin: "4px 0" }}>
        Totales
      </Divider>
      <Row justify="end">
        <Col xs={24} sm={24} md={16} lg={12} xl={10}>
          <Descriptions
            items={items({
              grupotipoafectacionigv,
              preciounitario,
              cantidad,
              valorunitario,
              valorigvunitario,
            })}
            layout="horizontal"
            bordered
            column={1}
            size="small"
            labelStyle={{
              textAlign: "end",
              width: "60%",
              padding: "4px 8px",
              backgroundColor: "#fff",
              fontWeight: "bold",
            }}
            contentStyle={{ textAlign: "end", padding: "4px 8px" }}
          />
        </Col>
      </Row>
    </>
  );
};
