import { Col, ConfigProvider, Empty, Form, Row } from "antd";
import React, { useEffect } from "react";
import {
  CondicionPago,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { ContadoMediosPagoTable } from "./ContadoMediosPagoTable";
import { CondicionPagoSelect } from "../reusable/CondicionPagoSelect";
import { TableEmptyUi } from "../../ui/TableEmptyUi";
import { ButtonUi } from "../../ui/ButtonUi";

export const MediosPagoComprobanteCompra = () => {
  const form = Form.useFormInstance();
  const tienepagos = Form.useWatch("tienepagos", form);
  const condicionpago = Form.useWatch("condicionpago", form);
  const mediospago = Form.useWatch("mediospago", form);
  useEffect(() => {
    if (tienepagos) {
      if (condicionpago === CondicionPago.CONTADO) {
        if (mediospago?.length < 1) {
          form.setFieldValue("mediospago", [
            {
              id: new Date().valueOf(),
              mediopago: null,
              referencia: null,
              monto: null,
              requiredreferencia: false,
            },
          ]);
        }
      }
    }
  }, [tienepagos, condicionpago]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <Form.Item name="condicionpago">
            <CondicionPagoSelect />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.List
            name="mediospago"
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_, value) => {
                  if (
                    CondicionPago.CONTADO === getFieldValue("condicionpago")
                  ) {
                    if (getFieldValue("tienepagos") && value.length < 1)
                      return Promise.reject(
                        new Error("Ingrese al menos un medio de pago")
                      );
                  }
                },
              }),
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.length > 0 ? (
                  <ContadoMediosPagoTable
                    data={fields}
                    Remove={remove}
                    Add={add}
                  />
                ) : (
                  <Empty
                    description="No existe medios de pago"
                    style={{ marginBottom: 15 }}
                  >
                    <ButtonUi
                      typeUi={TipoButtonConstants.AgregarTable}
                      onClick={() =>
                        add({
                          id: new Date().valueOf(),
                          mediopago: null,
                          referencia: null,
                          monto: null,
                          requiredreferencia: false,
                        })
                      }
                    />
                  </Empty>
                )}
                {errors?.length > 0 && (
                  <Form.Item className="ui-errorlist">
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </>
  );
};
