import React, { useState } from "react";
import { get_inventory_item_by_store_and_name_or_sku } from "../../../actions/inventario";
import { SearchSelectUI } from "../../ui/SearchSelectUI";

export const ProductoAlmacenSelect = ({ almacen, ...props }) => {
  const [productos, setProductos] = useState([]);
  const OnSearch = async (value, almacen) => {
    const data = await get_inventory_item_by_store_and_name_or_sku(
      almacen,
      value
    );
    setProductos(data);
  };

  // const OnLoadProductos = async (almacen) => {
  //   const datos = await get_inventory_item_by_store_and_name_or_sku(almacen);
  //   setProductos(datos);
  // };
  // useEffect(() => {
  //   if (almacen !== null) {
  //     OnSearch(almacen);
  //   }
  // }, [almacen]);

  // return (
  //   <SelectUi
  //     data={productos}
  //     allowClear
  //     showSearch
  //     optionFilterProp="children"
  //     filterOption={(input, option) =>
  //       (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //     }
  //     {...props}
  //   />
  // );
  return <SearchSelectUI handleSearch={OnSearch} data={productos} {...props} />;
};
