import React from "react";
import {
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { CardUi } from "../../../ui/CardUi";
import { ButtonUi } from "../../../ui/ButtonUi";
import {
  DateFormats,
  EstadoConsolidadoTelaConstants,
  TipoButtonConstants,
} from "../../../../types/typesConstants";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";
import { DetalleConsolidadoTelaContent } from "./DetalleConsolidadoTelaContent";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";

const items = ({ cantidadtotal, pesobrutototal, pesonetototal }) => [
  {
    key: "cantidadtotal",
    label: "Cantidad",
    children: <>{parseFloat(cantidadtotal).toFixed(2)}</>,
  },
  {
    key: "pesobrutototal",
    label: "Peso bruto total(KG)",
    children: <>{parseFloat(pesobrutototal).toFixed(2)}</>,
  },
  {
    key: "pesonetototal",
    label: "Peso neto total(KG)",
    children: <>{parseFloat(pesonetototal).toFixed(2)}</>,
  },
];

export const ConsolidadoTelaForm = ({ handleClose }) => {
  const form = Form.useFormInstance();
  const estado = Form.useWatch("estado", form);
  const cantidadtotal = Form.useWatch("cantidadtotal", form);
  const pesobrutototal = Form.useWatch("pesobrutototal", form);
  const pesonetototal = Form.useWatch("pesonetototal", form);
  return (
    <CardUi
      title={
        <Space wrap>
          <span>Consolidado tela</span>
          {estado === EstadoConsolidadoTelaConstants.PROCESO ? (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Estado: SIN PROCESAR
            </Tag>
          ) : (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Estado: FINALIZADO
            </Tag>
          )}
        </Space>
      }
      size="small"
      extra={[
        <Space className="site-button-ghost-wrapper" wrap key="Opts">
          <Tooltip title="Salir" key="1">
            <ButtonUi
              typeUi={TipoButtonConstants.Cancelar}
              onClick={handleClose}
            />
          </Tooltip>
          <Tooltip title="Guardar" key="2">
            <ButtonUi
              htmlType="submit"
              form="FormConsolidadoTela"
              typeUi={TipoButtonConstants.Guardar}
            />
          </Tooltip>
        </Space>,
      ]}
    >
      <FormItemHiddenUi name="id" />
      <FormItemHiddenUi name="estado" />
      <FormItemHiddenUi name="programaciontintoreria" />
      <FormItemHiddenUi name="cantidadtotal" />
      <FormItemHiddenUi name="pesobrutototal" />
      <FormItemHiddenUi name="pesonetototal" />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={16}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={8} xl={6}>
              <Form.Item name="codigo" label="Programación Tintoreria">
                <Input readOnly variant="borderless" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={18}>
              <Form.Item name="proveedor_nombre" label="Proveedor">
                <Input readOnly variant="filled" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Descriptions
                layout="vertical"
                size="small"
                items={items({
                  cantidadtotal,
                  pesobrutototal,
                  pesonetototal,
                })}
                colon={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={24}>
              <Form.Item name="fechatintoreria" label="Fecha tintoreria">
                <DatePicker
                  format={DateFormats.frontend}
                  disabled
                  variant="filled"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fechaconsolidacion"
                label="Fecha consolidación"
                rules={[
                  {
                    required: true,
                    message: "La fecha de consolidación es obligatoria",
                  },
                ]}
              >
                <DatePicker format={DateFormats.frontend} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DetalleConsolidadoTelaContent />
        </Col>
      </Row>
    </CardUi>
  );
};
