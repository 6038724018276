import { Card, Col, Divider, Row, Form } from "antd";
import React from "react";
import { DatosClienteDiv } from "./DatosClienteDiv";
import { DatosComprobante } from "./DatosComprobante";
import { MediosPagoContent } from "./MediosPagoContent";
import { SummaryOrder } from "./SummaryOrder";
import { DescuentoContent } from "./DescuentoContent";
import { ClienteModal } from "../../../generales/clientes/modal/ClienteModal";
import { FormUi } from "../../../ui/FormUi";
import { ButtonsChangeSteps } from "../ButtonsChangeSteps";
import { FormItemHiddenUi } from "../../../ui/FormItemHiddenUi";

export const InformacionPagoStep = ({
  handleSubmit,
  initialValues,
  stepsLength,
}) => {
  const [form] = Form.useForm();
  return (
    <>
      <FormUi
        form={form}
        name="FormStep2"
        autoComplete="off"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <FormItemHiddenUi name="terminalcajero" />
        <FormItemHiddenUi name="tipodocumentosunat" />
        <FormItemHiddenUi name="moneda" />
        <FormItemHiddenUi name="descuento" />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col sm={24} md={24} lg={14} xl={16}>
            <Card
              title={"Datos del documento"}
              size="small"
              key="card_datos"
              styles={{ header: { fontSize: 16 } }}
            >
              <Divider orientation="left" style={{ margin: "10px 0" }}>
                Datos comprobante
              </Divider>
              <DatosComprobante />
              <Divider orientation="left" style={{ margin: "10px 0" }}>
                Cliente
              </Divider>
              <DatosClienteDiv />
              <DescuentoContent />
              <Divider orientation="left" style={{ margin: "10px 0" }}>
                Método de pago
              </Divider>
              <MediosPagoContent />
            </Card>
          </Col>
          <Col sm={24} md={24} lg={10} xl={8}>
            <Card
              title="Resumen de Orden"
              size="small"
              styles={{ header: { fontSize: 16 } }}
            >
              <SummaryOrder
                detallecomprobante={initialValues?.detallecomprobante}
              />
            </Card>
          </Col>
        </Row>
        <ButtonsChangeSteps stepsLength={stepsLength} />
      </FormUi>
      <ClienteModal />
    </>
  );
};
