import { Checkbox, Col, Form, InputNumber, Row } from "antd";
import React from "react";

export const DescuentoPart = () => {
  const form = Form.useFormInstance();
  const tienedescuento = Form.useWatch("tienedescuento", form);
  const handleChangeTieneDescuento = (e) => {
    console.log(e);
    form.setFieldsValue({ tienedescuento: e.target?.checked, descuentos: 0 });
  };
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={6} lg={6} xl={5}>
          <Form.Item name="tienedescuento" valuePropName="checked">
            <Checkbox onChange={handleChangeTieneDescuento}>
              ¿Añadir descuento?
            </Checkbox>
          </Form.Item>
        </Col>
        {tienedescuento && (
          <Col xs={24} sm={24} md={6} lg={4} xl={4}>
            <Form.Item name="descuentos">
              <InputNumber
                placeholder="descuento"
                precision={2}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};
