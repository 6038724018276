import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { search_items_and_inventories_by_store_and_query } from "../../../actions/comprobante";

export const InputSearchDropdown = ({ almacen, label, ...restProps }) => {
  const form = Form.useFormInstance();
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState([]);

  const set_Item = (
    item = null,
    item_nombre = null,
    inventario = null,
    unidadmedida = null,
    unidadmedida_nombre = null,
    cantidad = 0,
    preciounitario = 0,
    stockactual = 0,
    manejalotes = false,
    manejaseries = false,
    lotes = null,
    series = null,
    tipoafectacionigv = null
  ) => {
    form.setFieldsValue({
      item,
      item_nombre,
      inventario,
      unidadmedida,
      unidadmedida_nombre,
      cantidad,
      preciounitario,
      stockactual,
      manejalotes,
      manejaseries,
      lotes,
      series,
      tipoafectacionigv,
    });
  };
  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await search_items_and_inventories_by_store_and_query(
        almacen,
        value
      );
      const options = data.map((item) => ({
        label: item?.nombre,
        key: item?.id,
      }));
      if (data?.length > 0) {
        setItems(options);
        setItemsData(data);
      } else {
        setItems([]);
        setItemsData([]);
        set_Item();
      }
    } else {
      //Clear useState and setFieldValue
      setItems([]);
      setItemsData([]);
      set_Item();
    }
  };
  const onClick = ({ key }) => {
    const itemSelect = itemsData?.find((x) => x.id === parseInt(key));
    set_Item(
      itemSelect?.id,
      itemSelect?.nombre,
      itemSelect?.inventario,
      itemSelect?.unidadmedida,
      itemSelect?.prefijounidadmedida,
      1,
      itemSelect?.precioventa,
      itemSelect?.stockactual,
      itemSelect?.manejalotes,
      itemSelect?.manejaseries,
      itemSelect?.lotes,
      itemSelect?.series,
      itemSelect?.tipoafectacionigv
    );
  };

  return (
    <Dropdown menu={{ items, onClick }}>
      <Form.Item
        name="item_nombre"
        label={label}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const item = getFieldValue("item");
              if (item) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("El item/servicio es obligatorio")
              );
            },
          }),
        ]}
      >
        <Input.Search
          allowClear
          enterButton
          onSearch={handleSearch}
          placeholder="Buscar item/servicio"
          {...restProps}
        />
      </Form.Item>
    </Dropdown>
  );
};
