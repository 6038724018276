import { Dropdown, Form, Input } from "antd";
import React, { useState } from "react";
import { search_items_by_param } from "../../../../../actions/produccion";
import { EstadoProgramacionTejido } from "../../../../../types/typesConstants";

export const InputSearchDropdown = ({ field, name, label, ...restProps }) => {
  const form = Form.useFormInstance();
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState([]);

  const set_Item = (field, key, item_nombre) => {
    form.setFields([
      {
        name: ["detalleprogramaciontintoreria", field.name, "item"],
        value: key,
      },
      {
        name: ["detalleprogramaciontintoreria", field.name, "item_nombre"],
        value: item_nombre,
      },
    ]);
  };
  const handleSearch = async (value, event) => {
    event.preventDefault();
    if (value?.length > 0) {
      const data = await search_items_by_param(value);
      const options = data.map((item) => ({
        label: item?.label,
        key: item?.value,
      }));
      if (data?.length > 0) {
        setItems(options);
        setItemsData(data);
      } else {
        setItems([]);
        setItemsData([]);
        set_Item(field, null, null);
      }
    } else {
      //Clear useState and setFieldValue
      setItems([]);
      setItemsData([]);
      set_Item(field, null, null);
    }
  };
  const onClick = ({ key }) => {
    const itemSelect = itemsData?.find((x) => x.value === parseInt(key));
    set_Item(field, key, itemSelect?.label);
  };

  return (
    <Dropdown menu={{ items, onClick }}>
      <Form.Item
        name={[field.name, "item_nombre"]}
        label={label}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const row = getFieldValue("detalleprogramaciontintoreria");
              const { estado, item } = row?.[field.name];
              if (
                (item && estado === EstadoProgramacionTejido.FINALIZADO) ||
                estado === EstadoProgramacionTejido.PROCESO
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("El item/servicio es obligatorio")
              );
            },
          }),
        ]}
      >
        <Input.Search
          allowClear
          enterButton
          onSearch={handleSearch}
          {...restProps}
        />
      </Form.Item>
    </Dropdown>
  );
};
