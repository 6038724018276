import React, { useState } from "react";
import { CardUi } from "../../ui/CardUi";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import {
  DateFormats,
  TipoButtonConstants,
} from "../../../types/typesConstants";
import { ButtonUi } from "../../ui/ButtonUi";
import { FormItemHiddenUi } from "../../ui/FormItemHiddenUi";
import { TipoComprobanteSelect } from "./notaventa/TipoComprobanteSelect";
import { MonedasSelect } from "../../generales/monedas/MonedasSelect";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { SerieComprontesSelect } from "../reusable/SerieComprontesSelect";
import { ClienteSelect } from "../puntoventa/reusable/ClienteSelect";
import { ComprobantePagoFormTable } from "./ComprobantePagoFormTable";
import { FormaPagoSelect } from "../reusable/FormaPagoSelect";

export const ComprobantePagoForm = ({
  handleNuevoDetalle,
  handleCloseForm,
}) => {
  const form = Form.useFormInstance();
  const detallescomprobantepago = Form.useWatch(
    "detallescomprobantepago",
    form
  );
  const tipocomprobante = Form.useWatch("tipocomprobante", form);
  const tienepagos = Form.useWatch("tienepagos", form);
  const tieneobservacion = Form.useWatch("tieneobservacion", form);
  const { activeCajaAbierta } = useSelector((state) => state.caja);

  return (
    <>
      <CardUi
        title={
          <Space wrap>
            <span>Comprobante Pago</span>
            {/* <EstadoProgramacionTag
            estado={
              activeProgramacionTejido
                ? activeProgramacionTejido?.estado
                : "nuevo"
            }
            estado_nombre={
              activeProgramacionTejido
                ? "Estado : " + activeProgramacionTejido?.nombreestado
                : "Estado : NUEVO"
            }
          /> */}
          </Space>
        }
        size="small"
        extra={[
          <Space className="site-button-ghost-wrapper" wrap key="Opts">
            <Tooltip title="Salir" key="1">
              <ButtonUi
                typeUi={TipoButtonConstants.Cancelar}
                onClick={handleCloseForm}
              />
            </Tooltip>
            <Tooltip title="Guardar" key="2">
              <ButtonUi
                htmlType="submit"
                form="FormComprobantePago"
                typeUi={TipoButtonConstants.Guardar}
              />
            </Tooltip>
          </Space>,
        ]}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <FormItemHiddenUi name="id" />
            <FormItemHiddenUi name="montoingresado" />
            <FormItemHiddenUi name="montovuelto" />
            <FormItemHiddenUi name="montototalgravadas" />
            <FormItemHiddenUi name="montototalexoneradas" />
            <FormItemHiddenUi name="montototalinafectas" />
            <FormItemHiddenUi name="montoigvtotal" />
            <FormItemHiddenUi name="totaldescuentos" />
            <FormItemHiddenUi name="descuentoglobal" />
            <FormItemHiddenUi name="montototaldescuento" />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <Form.Item
                  name="tipocomprobante"
                  label="Tipo comprobante"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione el tipo de comprobante",
                    },
                  ]}
                >
                  <TipoComprobanteSelect
                    placeholder="[-Seleccione-]"
                    terminalcajero={activeCajaAbierta?.terminalcajero}
                    onChange={(e) => {
                      form.setFieldsValue({
                        seriecomprobante: null,
                        cliente: null,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                <Form.Item
                  name="seriecomprobante"
                  label="Serie comprobante"
                  rules={[
                    { required: true, message: "La serie es obligatoria" },
                  ]}
                >
                  <SerieComprontesSelect
                    terminalcajero={activeCajaAbierta?.terminalcajero}
                    tipocomprobante={tipocomprobante}
                    placeholder="[-Seleccione-]"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={4} xl={3}>
                <Form.Item
                  name="fechaemision"
                  label="Fecha Emisión"
                  rules={[
                    {
                      required: true,
                      message: "La fecha de emisión es obligatoria",
                    },
                  ]}
                  required={false}
                >
                  <DatePicker format={DateFormats.frontend} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={4} xl={3}>
                <Form.Item
                  name="fechavencimiento"
                  label="Fecha Vencimiento"
                  rules={[
                    {
                      required: true,
                      message: "La fecha de vencimiento es obligatoria",
                    },
                  ]}
                  required={false}
                >
                  <DatePicker format={DateFormats.frontend} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                <Form.Item name="formapago" label="Forma pago">
                  <FormaPagoSelect placeholder="[-Seleccione-]" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={9}>
                <Form.Item
                  name="cliente"
                  label="Cliente"
                  rules={[
                    {
                      required: true,
                      message: "El cliente es obligatorio",
                    },
                  ]}
                  required={false}
                  shouldUpdate
                >
                  <ClienteSelect
                    tipocomprobante={tipocomprobante}
                    placeholder="[-Seleccione cliente-]"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={4}>
                <Form.Item
                  name="moneda"
                  label="Moneda"
                  rules={[
                    {
                      required: true,
                      message: "La moneda es obligatoria",
                    },
                  ]}
                  required={false}
                >
                  <MonedasSelect placeholder="[-Seleccione-]" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Space>
                  <Form.Item name="tienepagos" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  ¿Desea agregar pagos a esta compra?
                </Space>

                {/* {tienepagos && <MediosPagoComprobanteCompra />} */}
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Space>
                  <Form.Item name="tieneobservacion" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                  Añadir observación
                </Space>
              </Col>
            </Row>
            {tieneobservacion && (
              <Row>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <Form.Item name="observacion">
                    <Input.TextArea rows={2} placeholder="Observaciones" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CardUi
                  title={
                    <span>
                      <UnorderedListOutlined /> Detalle comprobante pago
                    </span>
                  }
                  size="small"
                  type="inner"
                  name="detalle"
                >
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <ButtonUi
                        typeUi={TipoButtonConstants.AgregarTable}
                        onClick={handleNuevoDetalle}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.List
                        name="detallescomprobantepago"
                        rules={[
                          {
                            validator: async (_, value) => {
                              if (value < 1)
                                return Promise.reject(
                                  new Error(
                                    "Ingrese al menos un item en el detalle de pago"
                                  )
                                );
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            <ComprobantePagoFormTable
                              detallescomprobantepago={detallescomprobantepago}
                              handleDelete={remove}
                              handleOpenModal={handleNuevoDetalle}
                            />
                            <Form.Item className="ui-errorlist">
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </CardUi>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardUi>
    </>
  );
};
