import { Form, Table } from "antd";
import React, { useEffect, useState } from "react";
import { parseNumeric } from "../../../helpers/utils";

const RowTotalesAfectacionIGV = ({ totales }) => (
  <>
    {totales?.map((e, index) => (
      <Table.Summary.Row key={index}>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>{e?.label}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {e.total}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ))}
  </>
);

export const ComprobantePagoTableSummary = () => {
  const form = Form.useFormInstance();
  const detallescomprobantepago = Form.useWatch(
    "detallescomprobantepago",
    form
  );
  const descuentoglobal = Form.useWatch("descuentoglobal", form);
  const [sumTotalesAfectacionIGV, setSumTotalesAfectacionIGV] = useState([]);
  const [sumTotales, setSumTotales] = useState(null);
  const fn_CalcularTotales = (data) => {
    let montototalgravadas = 0;
    let montototalinafectas = 0;
    let montototalexoneradas = 0;
    let montototalgratuitas = 0;
    let montototalexportaciones = 0;
    let totaldescuentos = 0;
    let montoigvtotal = 0;
    let montototal = 0;

    data?.forEach((e) => {
      const _grupotipoafectacionigv = String(
        e?.grupotipoafectacionigv
      ).toUpperCase();
      if (_grupotipoafectacionigv === "OPE. GRAVADA") {
        montototalgravadas += e.valorventabruto;
      } else if (_grupotipoafectacionigv === "OPE. EXONERADA") {
        montototalexoneradas += e.valorventabruto;
      } else if (_grupotipoafectacionigv === "OPE. INAFECTA") {
        montototalinafectas += e.valorventabruto;
      } else if (_grupotipoafectacionigv === "EXPORTACION") {
        montototalexportaciones += e.valorventabruto;
      } else if (_grupotipoafectacionigv === "OPE. GRATUITA") {
        montototalgratuitas += e.valorventabruto;
      }
      montoigvtotal += parseFloat(e.valorigv);
      montototal += parseFloat(e.valorventa);
      totaldescuentos += parseNumeric(e.descuentos);
    });
    montototalgravadas = parseNumeric(montototalgravadas);
    montototalexoneradas = parseNumeric(montototalexoneradas);
    montototalinafectas = parseNumeric(montototalinafectas);
    montoigvtotal = parseNumeric(montoigvtotal);
    const montototaldescuento = parseNumeric(descuentoglobal) + totaldescuentos;
    let totales = [];
    if (montototalgravadas > 0) {
      totales = [
        ...totales,
        {
          label: "Ope. Gravada",
          total: montototalgravadas.toFixed(2),
        },
      ];
    }
    if (montototalexoneradas > 0) {
      totales = [
        ...totales,
        {
          label: "Ope. Exonerada",
          total: montototalexoneradas.toFixed(2),
        },
      ];
    }
    if (montototalinafectas > 0) {
      totales = [
        ...totales,
        {
          label: "Ope. Inafecta",
          total: montototalinafectas.toFixed(2),
        },
      ];
    }

    setSumTotalesAfectacionIGV(totales);
    setSumTotales({ montoigvtotal, totaldescuentos, montototal });
    form.setFieldsValue({
      montototalgravadas,
      montototalexoneradas,
      montototalinafectas,
      montoigvtotal,
      totaldescuentos,
      montototaldescuento,
    });
  };
  useEffect(() => {
    fn_CalcularTotales(detallescomprobantepago);
  }, [detallescomprobantepago]);

  return (
    <>
      <RowTotalesAfectacionIGV totales={sumTotalesAfectacionIGV} />
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>IGV</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseNumeric(sumTotales?.montoigvtotal).toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>Descuento</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseNumeric(sumTotales?.totaldescuentos).toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>Importe Total</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseNumeric(sumTotales?.montototal).toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
