import { Form, Modal, Upload } from "antd";
import React, { useState } from "react";
import { is_valid_image_file, to_Base64 } from "../../helpers/utils";
import { useEffect } from "react";

export const ImageUploadUi = ({
  name,
  filelist,
  typeAccept = "image/jpeg,image/jpg,image/png",
}) => {
  const form = Form.useFormInstance();
  const { setFieldValue } = form;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState(filelist);
  const propsUpload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFieldValue(name, newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: ({ file, fileList: newFileList }) => {
      if (file?.status !== "removed") {
        const is_valida = is_valid_image_file(file);
        if (is_valida) {
          setFieldValue(name, newFileList);
          setFileList(newFileList);
        }
      }
    },
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await to_Base64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    },
    fileList,
  };
  useEffect(() => {
    setFileList(filelist);
  }, [filelist]);

  const handleCancel = () => setPreviewVisible(false);

  return (
    <>
      <Upload listType="picture-card" {...propsUpload} accept={typeAccept}>
        {fileList?.length < 1 && "+ Upload"}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt=""
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
