import { Col, Row, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiShowForm } from "../../../actions/ui";
import { TerminalCajeroScreen } from "./TerminalCajeroScreen";
import { TerminalCajeroTable } from "./TerminalCajeroTable";
import { ButtonUi } from "../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../types/typesConstants";
import { PageHeaderUi } from "../../ui/PageHeaderUi";
import { TerminalCajeroSearchTable } from "./TerminalCajeroSearchTable";

const TerminalCajeroListScreen = () => {
  const dispatch = useDispatch();
  const { terminalescajero } = useSelector((state) => state.venta);
  const { loading } = useSelector((state) => state.ui);

  const handleNuevo = () => {
    dispatch(uiShowForm());
  };

  return (
    <>
      <PageHeaderUi
        title="Terminal Cajero"
        subTitle="Listado"
        extra={[
          <Space key="options" wrap>
            <ButtonUi
              key="nuevo"
              typeUi={TipoButtonConstants.Nuevo}
              onClick={handleNuevo}
            />
          </Space>,
        ]}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <TerminalCajeroSearchTable />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <TerminalCajeroTable
              terminalescajero={terminalescajero}
              loading={loading}
            />
          </Col>
        </Row>
      </PageHeaderUi>
    </>
  );
};

export const TerminalCajeroList = () => {
  const { visibleForm } = useSelector((state) => state.ui);
  if (visibleForm) {
    return <TerminalCajeroScreen />;
  } else {
    return <TerminalCajeroListScreen />;
  }
};

export default TerminalCajeroList;
