import React from "react";
import { TableUi } from "../../../../../ui/TableUi";
import { Form, Tag, Tooltip } from "antd";
import { ButtonUi } from "../../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../../types/typesConstants";
const columns_table = (acciones) => {
  const { handleSelect } = acciones;
  return [
    {
      title: "",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => (
        <>
          <Tooltip title="Seleccionar">
            <ButtonUi
              typeUi={TipoButtonConstants.Seleccionar}
              size="small"
              onClick={() => handleSelect(record)}
              displayText={false}
              ghost
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "item_nombre",
      key: "item_nombre",
      // render: (text, record, index) => (
      //   <>
      //   {console.log(record)}
      //     {text + " "}
      //     {record?.manejalotes && (
      //       <Tag color="success">Lote : {record.numero_lote}</Tag>
      //     )}{" "}
      //     {record?.manejaseries && (
      //       <Tag color="success">Serie : {record.numero_serie}</Tag>
      //     )}
      //   </>
      // ),
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "U.Medida",
      dataIndex: "unidadmedida_nombre",
      key: "unidadmedida_nombre",
    },
    {
      title: "Precio unidad",
      dataIndex: "preciounitario",
      key: "preciounitario",
      align: "right",
    },
    {
      title: "Valor compra",
      dataIndex: "valorcompra",
      key: "valorcompra",
      align: "right",
    },
  ];
};
export const SearchComprobanteCompraTable = ({ data }) => {
  const form = Form.useFormInstance();
  const handleSelect = (data) => {
    form.setFieldValue("detallecomprobantecompra", data);
    form.submit();
  };
  return (
    <TableUi
      columns={columns_table({
        handleSelect,
      })}
      rowKey="id"
      dataSource={data}
      size="small"
    />
  );
};
